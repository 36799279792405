// Search component
.component-search-filter {
  margin-bottom: 120px;

  &.active {
    @include media-breakpoint-down(md) {
      .top-search,
      .filter-sidebar,
      .mobile-extra-filters {
        display: block;
      }

      .top-search {
        margin-bottom: 0;
      }

      .mobile-filter {
        margin-bottom: 30px;
        background: transparent;
        border-bottom: 1px solid $grey;

        .cta {
          background: transparent;
          border: 0;
          color: $blue;
          padding-left: 20px;

          &.js-mob-filter {
            display: none;
          }

          &.hidden-cta {
            display: table;
          }

          &:before {
            background: url('../app/images/close-filters-ico.svg') no-repeat;
            background-size: 10px 10px;
            width: 10px;
            height: 10px;
            left: 0;
          }
        }
      }
    }
  }

  .mobile-extra-filters {
    display: none;

    label {
      font-size: 12px;
      line-height: 32px;
      color: $blue;
      background: $whitesmoke;
      border-radius: 16px;
      padding: 0 35px 0 15px;
      position: relative;
      margin-right: 5px;
      margin-bottom: 5px;

      &:before {
        display: none;
      }

      &:after {
        background: url('../app/images/close-filters-ico.svg') no-repeat;
        background-size: 10px 10px;
        width: 10px;
        height: 10px;
        position: absolute;
        top: 50%;
        right: 15px;
        left: auto;
        @include transform(translateY(-50%));
      }
    }
  }

  .mobile-filter {
    background: $whitesmoke;
    border-top: 1px solid $grey;
    padding: 15px 0;
    margin-bottom: 50px;

    &.active {
      .reset-filters {
        display: block;
      }

      .first-cta {
        border-right: 1px solid $grey;
      }

      .extractas {
        margin-bottom: 20px;
      }
    }

    .reset-filters {
      display: none;
      font-size: 12px;
      line-height: 20px;
      color: $lightblue;
      font-weight: $regular;
      text-align: right;
      padding: 11px 25px 12px;
    }

    .cta {
      &.hidden-cta {
        display: none;
      }

      &:before {
        background: url(../app/images/filter-ico.svg) no-repeat;
        background-size: 14px 12px;
        width: 14px;
        height: 12px;
        margin-right: 15px;
      }
    }
  }

  .top-search {
    background: $whitesmoke;
    padding: 30px 0;
    margin-bottom: 100px;
    border-top: 1px solid $grey;

    @include media-breakpoint-down(md) {
      display: none;
      background: transparent;
      border: 0;
      padding: 0;
      margin-bottom: 15px;
    }
  }

  .pagination {
    margin: 0;
    @include align-items(center);
    @include justify-content(center);

    li {
      border-bottom: 1px solid $grey;

      @include media-breakpoint-down(md) {
        border: 0;

        &:not(.active):not(:last-child) {
          display: none;
        }

        &:last-child {
          &.active {
            &:after {
              display: none;
            }
          }
        }

        &.active {
          &:after {
            content: '/';
            display: inline-block;
          }
        }
      }

      &.active {
        .page-link {
          background: transparent;
          color: $blue;

          @include media-breakpoint-down(md) {
            display: inline-block;
          }

          &:after {
            content: '';
            display: block;
            width: 100%;
            height: 2px;
            background: $blue;
            position: absolute;
            bottom: 0;
            left: 0;

            @include media-breakpoint-down(md) {
              display: none;
            }
          }
        }
      }

      &:not(.active) {
        .page-link {
          &:hover {
            &:after {
              content: '';
              display: block;
              width: 100%;
              height: 2px;
              background: rgba($blue, 0.2);
              position: absolute;
              bottom: 0;
              left: 0;

              @include media-breakpoint-down(md) {
                display: none
              }
            }
          }
        }
      }

      .page-link {
        font-size: 12px;
        line-height: 20px;
        color: rgba($black, 0.4);
        font-weight: $regular;
        border: 0;
        border-radius: 0;
        padding: 12px 18px;

        @include media-breakpoint-down(md) {
          font-size: 14px;
          color: $blue;
          padding: 5px;
        }

        &:hover,
        &:focus,
        &:active {
          outline: 0;
          box-shadow: none;
          background: transparent;
        }
      }
    }
  }

  .form-group {
    margin-bottom: 0;
  }

  .filter-sidebar {
    @include media-breakpoint-up(lg) {
      padding-right: 70px;
    }

    @include media-breakpoint-down(md) {
      display: none;
      margin-bottom: 30px;
    }

    .filtertitle {
      font-size: 12px;
      line-height: 20px;
      font-weight: $regular;
      letter-spacing: 0.51px;
      border-bottom: 1px solid $grey;
      position: relative;
      padding-bottom: 20px;
      margin-bottom: 0;

      &:before {
        content: '';
        display: inline-block;
        background: url(../app/images/filter-white-ico.svg) no-repeat;
        background-size: 14px 12px;
        width: 14px;
        height: 12px;
        margin-right: 15px;
      }
    }
    .reset-filters {
      display: none;
      font-size: 12px;
      line-height: 20px;
      color: $lightblue;
      font-weight: $regular;
      padding: 20px 0;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    &.active {
      .reset-filters {
        display: block;
      }
    }
  }

  .filter-tag {
    border-bottom: 1px solid $grey;

    @include media-breakpoint-down(md) {
      &:first-of-type {
        border-top: 1px solid $grey;
      }
    }

    &.active {
      .filters {
        display: block;
      }

      .crossico {
        &:before {
          opacity: 0;
        }

        &:after {
          @include transform(rotate(-90deg));
        }
      }
    }

    &:last-of-type {
      .js-filter {
        border: 0;
      }
    }

    .filtertitle {
    }

    .js-filter {
      position: relative;
      padding: 20px 0;

      &:hover {
        cursor: pointer;
      }
    }

    .filters {
      display: none;
    }

    .crossico {
      width: 15px;
      height: 15px;
      right: 0;
      opacity: 0.5;

      &:before,
      &:after {
        height: 15px;
        left: 7px;
      }
    }
  }

  .component-latestpress {
    margin: 0;

    .articles {
      .eventeaser {
        &:first-of-type {
          .fullevent {
            @include media-breakpoint-down(md) {
              border-top: 1px solid $grey;
              padding-top: 25px;
            }
          }
        }
      }
    }

    .eventeaser {
      color: $black;

      &.document {
        .articletype {
          background: url('../app/images/download-ico-white.svg') no-repeat;
          background-size: 16px 18px;
          width: 16px;
          height: 18px;
        }
      }

      &:first-of-type {
        .fullevent {
          @include media-breakpoint-down(md) {
            margin-bottom: 25px;
            padding-bottom: 25px;
            position: relative;
          }

          &:after {
            @include media-breakpoint-down(md) {
              display: block;
            }
          }
        }

        .fullevent {
          @include media-breakpoint-down(md) {
            display: block;
          }
        }
      }

      &:not(:first-of-type) {
        @include media-breakpoint-down(md) {
          display: block;
        }
      }

      .static-col {
        position: static;

        @include media-breakpoint-down(md) {
          position: relative;
        }
      }

      .articletype {
        background: url('../app/images/external-link-ico.svg') no-repeat;
        background-size: 20px 20px;
        width: 20px;
        height: 20px;
        position: absolute;
        right: 15px;
        bottom: 25px;

        &.downloadico {
          background: none;
        }
      }

      .infoarea {
        margin-bottom: 40px;

        @include media-breakpoint-down(md) {
          margin-bottom: 30px;
        }
      }
    }

    .hidden-pagination {
      display: none;
    }

    .filter-number {
      font-size: 12px;
      line-height: 20px;
      font-weight: $light;
      letter-spacing: 0.2px;
      position: relative;
      padding-bottom: 20px;
      margin-bottom: 0;
    }
  }

  .paginationsct {
    @include media-breakpoint-down(md) {
      @include align-items(center);
    }

    .cta {
      @include media-breakpoint-down(md) {
        content: '';
        text-indent: -999999px;
        padding: 11px 21px 12px 22px;

        &:before {
          background: url(../app/images/right-long-grey-arrow-ico.svg) no-repeat;
          background-size: 15px 11px;
          width: 15px;
          height: 11px;
          left: 15px;
        }
      }

      &:hover {
        cursor: pointer;
      }

      &.disabled {
        &:hover {
          cursor: default;
        }
      }
    }

    .left-col {
      .cta {
        &:before {
          @include transform(translateY(-50%) rotate(180deg));
        }
      }
    }

    .right-col {
      .cta {
        float: right;
      }
    }
  }
}