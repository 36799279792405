// In page navigation
.pagenav {
  background: $integrey;
  position: relative;
  overflow: hidden;

  &.fixedtop {
    position: fixed;
    top: 74px;
    left: 0;
    width: 100%;
    z-index: 3;
    @include transition(all 0.25s ease-in-out);

    @include media-breakpoint-up(lg) {
      position: fixed;
      top: 74px;
      left: 0;
      width: 100%;
      z-index: 3;
      @include transition(all 0.25s ease-in-out);

      &.updated {
        top: 117px;
        @include transition(all 0.25s ease-in-out);
      }
    }
  }

  ul {
    @extend .row;
    @include flex-wrap(nowrap);
    list-style-type: none;
    margin: 0 -20px;
    padding: 12px 0;
    width: calc(100% + 40px);
    overflow: auto;

    li {
      font-size: 12px;
      line-height: 20px;
      font-weight: $light;
      color: $black;
      padding: 0 4px;
      white-space: nowrap;

      &:first-child {
        font-weight: $medium;
      }

      &.active {
        span,
        a {
          background: $royalblue;
          color: $white;
          border-radius: 20px;

          &:hover {
            text-decoration: none;
          }
        }
      }

      &:not(.active) {
        &:hover {
          a {
            background: rgba($royalblue, 0.14);
            color: $black;
            border-radius: 20px;
            cursor: pointer;
            text-decoration: none;
          }
        }
      }

      span,
      a {
        display: block;
        padding: 6px 16px;
        color: inherit;

        &.active {
          color: red;
        }
      }
    }
  }
}


body {
  .main {
    &.extranav {
      padding-top: 173px;
      @include media-breakpoint-down(md) {
        padding-top: 130px;
      }
    }
  }
}