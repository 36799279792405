// Rich text component
.component-relatedcarousel {
  .hidden-dkt {
    margin-top: 40px;

    .cta {
      width: 100%;
      text-align: center;
      padding-left: 25px;
    }
  }

  .left-col {
    .inside {
      height: 100%;
      @include flexbox();
      @include flex-direction(column);
      @include justify-content(space-between);
    }

    .cta-sect {
      margin-bottom: 95px;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    .pagenum {
      font-size: 16px;
      line-height: 24px;

      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 20px;
        right: 15px;
      }

      @include media-breakpoint-down(md) {
        margin-bottom: 40px;
      }

      span {
        display: inline-block;
        font-weight: $regular;
        opacity: 0.5;

        &:first-child {
          opacity: 1;
        }

        &:nth-child(2) {
          margin: 0 3px;
        }
      }
    }
  }

  .right-col {
    position: static;
  }

  .relatedcarouselrow {
    position: relative;
  }

  .product-list {
    position: static;

    .product-col {
      @include media-breakpoint-down(md) {
        margin-bottom: 0;
      }

      &.slick-slide {
        @include media-breakpoint-down(md) {
          padding: 0 8px;
        }
      }
    }

    .slick-slider {
      position: static;

      .slick-next,
      .slick-prev {
        top: 100px;
        left: 15px;
        right: auto;
        z-index: 2;
        @extend .social-ico;

        @include media-breakpoint-down(md) {
          top: 80px;
          left: auto;
          right: 15px;
        }

        &:before {
          content: '';
          display: block;
          background: url('../app/images/right-grey-arrow-ico.svg') no-repeat;
          background-size: 8px 14px;
          width: 8px;
          height: 14px;
        }
      }

      .slick-prev {
        @include media-breakpoint-down(md) {
          right: 69px;
        }

        &:before {
          @include transform(rotate(-180deg));
        }
      }

      .slick-next {
        left: 69px;

        @include media-breakpoint-down(md) {
          left: auto;
        }
      }

      &.slick-no-slide {
        .slick-track, .slick-list {
          width: 100% !important;
          transform: inherit !important;
        }
        .slick-slide {
          float: none;
          display: inline-block;
        }
      }
    }

    .slick-list {
      padding: 0 11% 0 0 !important;

      @include media-breakpoint-down(md) {
        padding-right: 30% !important;
      }
    }

    .slick-track {
      @include media-breakpoint-down(md) {
        padding-left: 7px;
      }
    }
  }

  .product-item {
    a {
      &:hover {
        text-decoration: none;

        @include media-breakpoint-up(lg) {
          .product-img {
            &:after {
              width: 100%;
              @include transition(all 0.25s ease);
            }
          }

          .product-title {
            p {
              padding-left: 25px;
              @include transition(all 0.25s ease);

              &:before {
                width: 15px;
                @include transition(all 0.25s ease);
              }
            }
          }
        }
      }
    }

    .product-img {
      margin-bottom: 20px;
      position: relative;
      background: $whitesmoke;

      &:after {
        content: '';
        display: block;
        width: 0;
        height: 3px;
        background: $blue;
        position: absolute;
        left: 0;
        bottom: 0;
        @include transition(all 0.25s ease);
      }

      img {
        width: 100%;
      }
    }

    .product-title {
      h2 {
        line-height: 48px;
        margin-bottom: 5px;
      }

      p {
        color: $black;
        position: relative;
        @include transition(all 0.25s ease);

        &:before {
          content: "";
          display: block;
          background: url(../app/images/right-long-grey-arrow-ico.svg) no-repeat;
          background-size: 15px 11px;
          width: 0;
          height: 11px;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          @include transition(all 0.25s ease);
        }
      }
    }
  }
}