// Feature Panel Image component
.component-featureimgpanel {
  &.gray-theme {
    background: $whitesmoke;
    margin-bottom: 0;
    margin-top: -80px;
    padding-top: 80px;

    @include media-breakpoint-down(md) {
      margin-top: -60px;
      padding-top: 60px;
    }
  }

  .featureimgrow {
    margin: 0;

    &.img-right-align {
      @include flex-direction(row-reverse);
    }
  }

  .col {
    padding: 0;

    &.bluebg {
      padding: 15px 115px;
      @include flexbox();
      @include justify-content(center);
      @include flex-direction(column);

      @include media-breakpoint-down(lg) {
        padding: 30px 55px;
      }

      @include media-breakpoint-down(md) {
        padding: 30px 25px;
      }
    }

    &.imgcol {
      img {
        width: 100%;
      }
    }
  }

  .title-sct {
    margin-bottom: 25px;

    @include media-breakpoint-down(md) {
      width: 100%;
      max-width: 533px;
      margin: 0 auto 15px;
      text-align: left;
    }

    h6 {
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 2.16px;
      font-weight: $regular;
      text-transform: uppercase;
      margin-bottom: 0;

      @include media-breakpoint-down(md) {
        font-size: 10px;
        line-height: 20px;
        letter-spacing: 1.18px;
      }
    }
  }

  .description-sct {
    margin-bottom: 30px;

    @include media-breakpoint-down(md) {
      max-width: 533px;
      margin: 0 auto 30px;
    }
  }

  .cta-sct {
    @include media-breakpoint-down(md) {
      width: 100%;
      max-width: 533px;
      margin: 0 auto;
    }
  }

  .cta {
    @include media-breakpoint-down(md) {
      width: 100%;
      text-align: center;
      padding-left: 25px;
    }
  }
}