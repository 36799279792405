// Rich text component
.component-featurecarousel {
  margin-bottom: 25px;

  @include media-breakpoint-down(md) {
    margin-bottom: 50px;
  }

  .featurecarousel {
    background: $whitesmoke;
    position: relative;

    @include media-breakpoint-down(md) {
      padding: 0 0 70px;
    }
  }

  .featurecarouselrow {
    .col {
      padding: 15px;

      @include media-breakpoint-down(md) {
        position: static;
      }

      &.textcol {
        @include media-breakpoint-down(md) {
          padding: 25px;
        }

        .tags {
          margin-bottom: 15px;
        }

        .description-sct {
          p {
            @include media-breakpoint-down(md) {
              font-size: 14px;
              line-height: 24px;
            }
          }

          .morecontent {
            span {
              display: none;
            }

            .morelink {
              font-size: 12px;
              line-height: 18px;
              color: $black;
              font-weight: 500;
              text-decoration: underline;
              display: block;
              margin-top: 15px;
            }
          }
        }
      }
    }

    .inside {
      max-width: 390px;
      height: 100%;
      margin: 0 auto;
      @include flexbox();
      @include flex-direction(column);
      @include justify-content(center);

      @include media-breakpoint-down(md) {
        max-width: 563px;
      }
    }
  }

  .imgcol {
    text-align: center;

    @include media-breakpoint-down(md) {
      max-width: 563px;
      margin: 0 auto;
    }

    img {
      @include media-breakpoint-down(md) {
        padding: 15px;
      }
    }
  }

  .js-feature-carousel {
    &.slick-slider {
      position: static;

      .slick-slide {
        outline: 0;
        padding: 0 15px;
      }

      .slick-next,
      .slick-prev {
        top: auto;
        bottom: -22px;
        left: auto;
        right: 10px;
        z-index: 2;
        background: transparent;
        @extend .social-ico;

        @include media-breakpoint-down(md) {
          bottom: 0;
          right: 115px;
        }

        @include media-breakpoint-down(sm) {
          right: 15px;
        }

        &:before {
          content: '';
          display: block;
          background: url('../app/images/right-grey-arrow-ico.svg') no-repeat;
          background-size: 8px 14px;
          width: 8px;
          height: 14px;
        }
      }

      .slick-prev {
        right: 64px;

        @include media-breakpoint-down(md) {
          right: 169px;
        }

        @include media-breakpoint-down(sm) {
          right: 69px;
        }

        &:before {
          @include transform(rotate(-180deg));
        }
      }

      .slick-next {

        @include media-breakpoint-down(md) {
          left: auto;
        }
      }
    }
  }

  .slider-cnt {
    position: relative;

    @include media-breakpoint-down(md) {
      position: static;
    }
  }

  .slider-nav {
    position: relative;
    width: 100%;
    height: 70px;
    padding: 0 15px;
    margin-top: 40px;

    @include media-breakpoint-down(md) {
      max-width: 533px;
      height: 100px;
      padding-top: 15px;
      position: absolute;
      bottom: 0;
      left: 50%;
      @include transform(translateX(-50%));
    }
  }

  .pagenum {
    font-size: 14px;
    line-height: 24px;

    @include media-breakpoint-up(lg) {
      font-size: 20px;
      line-height: 28px;
      position: absolute;
      top: 30px;
      left: 15px;
    }

    @include media-breakpoint-down(md) {
      margin-top: 30px;
    }

    span {
      display: inline-block;
      font-weight: $regular;
      opacity: 0.5;

      &:first-child {
        opacity: 1;
      }

      &:nth-child(2) {
        margin: 0 5px;
      }
    }
  }

  .progress-bar {
    background-color: $grey;
  }
}