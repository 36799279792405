// Error Pages

.component-page-error {
  background: $whitesmoke;

  &.error-500 {
    .error-container {
      min-height: 100vh;
      @include flexbox();
      @include flex-direction(column);
      @include justify-content(stretch);
    }

    h1 {
      margin: 60px auto 20px;

      @include media-breakpoint-down(md) {
        margin: 60px 0;
      }
    }

    .inside {
      @include flex-grow(1);
    }

    .footer-copy {
      font-size: 12px;
      line-height: 14px;
      color: $blue;
      border-top: 1px solid $grey;
      padding: 20px 0;
    }
  }

  .inside {
    padding: 150px 0;
    margin: 0 auto;
    text-align: center;
    width: 100%;
    max-width: 940px;

    @include media-breakpoint-down(md) {
      padding: 60px 0;
    }
  }

  h1 {
    margin: 60px auto 80px;
    letter-spacing: 10px;

    @include media-breakpoint-down(md) {
      margin: 60px 0;
    }
  }

  h4 {
    letter-spacing: 0.6px;
    margin: 0;
  }

  form {
    width: 100%;
    max-width: 670px;
    margin: 0 auto 60px;

    input {
      padding: 21px 25px;
    }
  }

  .cta {
    margin: 0 auto;
    text-align: center;
  }

  .logo-error {
    margin: 0 auto 150px;
  }
}