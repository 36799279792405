// Tab component
.component-tabcontent {
  margin-top: 95px;

  @include media-breakpoint-down(md) {
    margin-top: 55px;
  }

  @include media-breakpoint-down(sm) {
    margin-top: 25px;
  }

  .nav-tabs {
    margin: 0 0 95px;
    border-bottom: 1px solid $grey;

    @include media-breakpoint-down(md) {
      margin: 0;
      border: 0;
      @include flexbox();
      @include flex-direction(column);
    }

    li {
      font-size: 16px;
      line-height: 24px;
      color: rgba($blue, 0.3);
      font-weight: $regular;
      margin: 0;

      @include media-breakpoint-down(md) {
        font-size: 14px;
        line-height: 20px;
      }

      @include media-breakpoint-up(lg) {
        &:first-child {
          a {
            padding-left: 0;

            &.active {
              &:after {
                left: 0;
                width: calc(100% - 20px);
              }
            }

            &:not(.active) {
              &:hover {
                &:after {
                  left: 0;
                  width: calc(100% - 20px);
                }
              }
            }
          }
        }
      }

      a {
        color: inherit;
        text-decoration: none;
        border: 0;
        padding: 12px 20px;
        position: relative;

        &.active {
          color: $blue;

          @include media-breakpoint-up(lg) {
            &:after {
              content: '';
              display: block;
              height: 3px;
              width: calc(100% - 40px);
              position: absolute;
              bottom: 0;
              left: 20px;
              background: $blue;
            }
          }
        }

        &:not(.active) {
          &:hover {
            @include media-breakpoint-up(lg) {
              &:after {
                content: '';
                display: block;
                height: 3px;
                width: calc(100% - 40px);
                position: absolute;
                bottom: 0;
                left: 20px;
                background: rgba($blue, 0.2);
              }
            }
          }
        }
      }
    }
  }

  .fakeselect {
    @include media-breakpoint-down(md) {
      margin: 0 auto 30px;
      max-width: 533px;
    }

    &.fixedtop {
      position: fixed;
      top: 74px;
      left: 0;
      width: 100%;
      z-index: 3;
      @include transition(all .25s ease-in-out);

      &.updated {
        top: 117px;
        @include transition(all .25s ease-in-out);
        @include media-breakpoint-down(md) {
          top: 74px;
        }
      }

      .dropdown-menu {
        max-width: 1370px;
        margin: 0 auto;
      }
    }
  }

  .dropdown-menu {
    @include media-breakpoint-up(lg) {
      display: block;
      position: relative;
      top: auto;
      left: auto;
      z-index: 1;
      float: none;
      min-width: none;
      padding: 0;
      margin: 0;
      border: 0;
      border-radius: 0;
    }
  }
}