// Hero News
.component-heronews {
  background: $white;

  .inside {
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(flex-end);
    height: 1px;
    min-height: 380px;
    padding: 10px 0;

    @include media-breakpoint-down(sm) {
      min-height: 320px;
    }
  }

  .display1,
  .display2 {
    text-transform: uppercase;
  }

  .display2 {
    margin-bottom: 90px;
  }

  .breadcrumbs {
    * {
      color: $blue;
    }

    li {
      &:after {
        color: $blue;
      }
    }
  }
}