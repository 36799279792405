// Hero Main Component
.component-main-hero {
  width: 100%;
  height: calc(100vh - 40px);
  margin-top: -77px;
  overflow: hidden;
  position: relative;

  @include media-breakpoint-down(sm) {
    height: 512px;
    margin-top: -74px;
  }

  &.homehero {
    margin-bottom: 80px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
    }

    .herocnt {
      .insidecnt {
        @include media-breakpoint-up(md) {
          margin-top: 50px;
        }

        .textbox {
          width: 100%;

          @include media-breakpoint-up(md) {
            height: 500px;
          }

          .extrainfo {
            @include media-breakpoint-down(md) {
              max-width: 533px;
              margin: 0 auto;
            }

            @include media-breakpoint-down(sm) {
              display: none;
            }
          }
        }

        .titlebox {
          .uptext {
            font-size: 48px;
            line-height: 70px;
            color: $white;
            letter-spacing: 11px;
            margin: 0;

            @include media-breakpoint-only(md) {
              font-size: 36px;
              line-height: 52px;
            }

            @include media-breakpoint-down(sm) {
              font-size: 28px;
              line-height: 120px;
              letter-spacing: 7px;
            }
          }

          p {
            font-size: 20px;
            line-height: 36px;
            font-weight: $light;
            letter-spacing: 0.6px;
            text-transform: none;
            max-width: 815px;
            margin: 0 auto;
          }
        }

        .cta {
          margin: 50px auto 0;
          text-transform: none;

          @include media-breakpoint-down(md) {
            width: 100%;
            text-align: center;
            padding-left: 25px;
          }
        }
      }
    }
  }

  .img-cnt {
    width: 100%;
    padding-top: calc(100vh - 40px);
    position: relative;
    z-index: -1;

    @include media-breakpoint-down(sm) {
      padding-top: 512px;
    }

    &:before {
      content: '';
      display: block;
      background: url(../app/images/mainherofill.png) no-repeat;
      background-size: 646px 660px;
      width: 646px;
      height: 660px;
      z-index: 1;
      position: absolute;
      top: 50%;
      left: 50%;
      @include transform(translate(-50%, -50%));

      @include media-breakpoint-down(sm) {
        background-size: 288px 294px;
        width: 288px;
        height: 294px;
      }
    }

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba($darkblue, 0.3);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  .inside {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  .herocnt {
    position: relative;
    height: 100%;

    .insidecnt {
      width: 100%;
      height: 100%;
      @include flexbox();
      @include justify-content(center);
      @include align-items(center);

      .textbox {
        width: 660px;
        height: 430px;
        text-align: center;
        @include flexbox();
        @include justify-content(center);
        @include align-items(center);
        @include flex-direction(column);

        @include media-breakpoint-down(sm) {
          height: 240px;
        }
      }

      .pagetitle {
        font-size: 16px;
        line-height: 32px;
        letter-spacing: 3.73px;
        color: $white;
        text-transform: uppercase;
        font-weight: $regular;

        @include media-breakpoint-down(sm) {
          font-size: 10px;
          line-height: 20px;
          letter-spacing: 2.32px;
        }
      }

      .titlebox {
        font-size: 48px;
        line-height: 70px;
        letter-spacing: 11px;
        color: $white;
        text-transform: uppercase;
        font-weight: $light;
        @include flexbox();
        width: 100%;
        height: 100%;
        margin: 0;
        @include justify-content(center);
        @include align-items(center);
        @include flex-direction(column);
        @include justify-content(space-between);

        @include media-breakpoint-down(sm) {
          font-size: 28px;
          line-height: 40px;
          letter-spacing: 7px;
        }
      }
    }

    .breadcrumbs {
      position: absolute;
      bottom: 25px;
      left: 15px;

      @include media-breakpoint-down(sm) {
        bottom: 15px;
      }

      * {
        color: $white;
      }
    }
  }
}

.hero-img {
  width: 100%;
  height: 605px;
  position: relative;
  overflow: hidden;

  @include media-breakpoint-down(lg) {
    height: 450px;
  }

  @include media-breakpoint-down(md) {
    height: 340px;
  }

  @include media-breakpoint-down(sm) {
    height: 140px;
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    @include transform(translate(-50%, -50%));
    min-width: 100%;
    min-height: 100%;
    z-index: -1;
  }
}

.maine-hero {
  &.extrainfo {
    background: $whitesmoke;
    padding: 50px 15px;

    p {
      font-size: 20px;
      line-height: 36px;
      margin: 0;
    }

    .cta {
      width: 100%;
      text-align: center;
      padding-left: 25px;
      margin-top: 50px;
      background: transparent;
    }
  }
}