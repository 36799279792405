.component-4colcardlist {
  margin-bottom: 60px;

  @include media-breakpoint-down(md) {
    margin-bottom: 0;
  }

  .right-col {
    @include media-breakpoint-down(md) {
      position: relative;
      overflow: hidden;
    }
  }

  .product-list {
    position: relative;

    .product-col {
      margin-bottom: 60px;

      @include media-breakpoint-down(sm) {
        margin-bottom: 0;
      }

      &.slick-slide {
        @include media-breakpoint-down(sm) {
          padding: 0 8px;
        }
      }
    }

    .slick-slider {
      position: static;

      .slick-next,
      .slick-prev {
        top: 22px;
        left: auto;
        right: 0;
        z-index: 2;
        @extend .social-ico;

        &:before {
          content: '';
          display: block;
          background: url('../app/images/right-grey-arrow-ico.svg') no-repeat;
          background-size: 8px 14px;
          width: 8px;
          height: 14px;
        }
      }

      .slick-prev {
        right: 54px;

        &:before {
          @include transform(rotate(-180deg));
        }
      }
    }

    .slick-list {
      padding: 0 30% 0 0 !important;
    }

    .slick-track {
      padding-left: 7px;
    }
  }

  .pagenavigation {
    display: none;

    @include media-breakpoint-down(sm) {
      display: block;
    }

    .pagenum {
      font-size: 16px;
      line-height: 24px;

      span {
        display: inline-block;
        font-weight: $regular;
        opacity: 0.5;
        height: 44px;
        margin-bottom: 20px;

        &:first-child {
          opacity: 1;
        }

        &:nth-child(2) {
          margin: 0 3px;
        }
      }
    }
  }

  .product-item {
    a {
      .product-title {
        @include media-breakpoint-down(sm) {
          padding-left: 25px;
        }
      }

      &:hover {
        text-decoration: none;

        @include media-breakpoint-up(lg) {
          .product-img {
            &:after {
              width: 100%;
              @include transition(all 0.25s ease);
            }
          }

          .product-title {
            padding-left: 25px;
            @include transition(all 0.25s ease);

            &:before {
              width: 15px;
              @include transition(all 0.25s ease);
            }
          }
        }
      }
    }

    .product-img {
      margin-bottom: 15px;
      position: relative;
      background: $whitesmoke;

      &:after {
        content: '';
        display: block;
        width: 0;
        height: 3px;
        background: $blue;
        position: absolute;
        left: 0;
        bottom: 0;
        @include transition(all 0.25s ease);
      }

      img {
        width: 100%;
      }
    }

    .product-title {
      color: $blue;
      position: relative;
      @include transition(all 0.25s ease);

      &:before {
        content: "";
        display: block;
        background: url(../app/images/right-long-grey-arrow-ico.svg) no-repeat;
        background-size: 15px 11px;
        width: 0;
        height: 11px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        @include transition(all 0.25s ease);

        @include media-breakpoint-down(sm) {
          width: 15px;
        }
      }
    }
  }
}
