.component-3colcardlist {
  background: $whitesmoke;
  padding: 80px 0;

  @include media-breakpoint-down(md) {
    padding: 60px 0;
  }

  &.gray-theme {
    background: $white;
    padding: 0 0 80px;
    margin-bottom: 0;

    @include media-breakpoint-down(md) {
      padding: 0 0 65px;
    }

    .comp-2col {
      &:nth-child(odd) {
        .row2col {
          @include flex-direction(row-reverse);
        }
      }

      &:nth-child(even) {
        .row2col {
          @include flex-direction(row);
        }
      }

      &:nth-last-child(-n+2) {
        @include media-breakpoint-only(md) {
          margin-bottom: 0;
        }
      }

      .infocol {
        background: $whitesmoke;
      }
    }
  }

  .comp-2col {
    margin-bottom: 40px;

    &:nth-of-type(2n) {
      .row2col {
        @include flex-direction(row-reverse);
      }
    }

    &:nth-last-child(-n+2) {
      @include media-breakpoint-only(md) {
        margin-bottom: 0;
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    .row2col {
      margin: 0;
    }

    .imagecol {
      padding: 0;

      @include media-breakpoint-only(lg) {
        max-width: 53%;
        @include flex(0 0 53%);
      }

      img {
        width: 100%;
      }
    }

    .infocol {
      padding: 40px;
      background: $white;
      @include flexbox();
      @include flex-direction(column);
      @include justify-content(stretch);

      @include media-breakpoint-only(lg) {
        padding: 32px;
        max-width: 47%;
        @include flex(0 0 47%);
      }

      @include media-breakpoint-down(md) {
        padding: 32px;
      }

      @include media-breakpoint-down(sm) {
        padding: 24px;
      }

      .inside {
        flex: 1;
        padding-bottom: 30px;
      }

      .cta {
        @include media-breakpoint-down(sm) {
          width: 100%;
          text-align: center;
          padding-left: 25px;
        }
      }
    }
  }
}
