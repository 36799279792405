// Latest news component
.component-latestnews {
  background: $whitesmoke;
  padding: 80px 0 60px;
  margin-bottom: 120px;

  @include media-breakpoint-down(md) {
    padding: 55px 0 35px;
    margin-bottom: 65px;
  }

  .news-col {
    margin-bottom: 25px;
    @include flex(1);

    @include media-breakpoint-down(md) {
      @include flex(none);
    }

    .inside {
      background: $white;
      padding: 25px;
      display: block;
      position: relative;
      height: 100%;
      @include flexbox();
      @include flex-direction(column);
      @include justify-content(flex-end);

      &:hover {
        text-decoration: none;
        color: inherit;

        @include media-breakpoint-up(md) {
          &:after {
            width: 100%;
            @include transition(all 0.25s ease);
          }

          h4 {
            text-decoration: underline;
          }

          .tags {
            background: $blue;
            color: $white;
            @include transition(background 0.25s ease);
          }

          .dates {
            text-decoration: none;
          }
        }
      }

      &:after {
        content: '';
        display: block;
        width: 0;
        height: 3px;
        background: $blue;
        position: absolute;
        left: 0;
        bottom: 0;
        @include transition(all 0.25s ease);
      }
    }

    .dates {
      color: rgba($black, 0.6);
      margin-bottom: 20px;
    }

    h4 {
      margin-bottom: 10px;
      @include flex-grow(1);
    }
  }
}