// Image Gallery
.component-imagegallery {
  margin-bottom: 120px;

  @include media-breakpoint-down(md) {
    margin-bottom: 65px;
  }

  .left-col {
    .inside {
      position: relative;
      height: 100%;
      @include flexbox();
      @include flex-direction(column);
      @include justify-content(space-between);
    }

    .pagenum {
      font-size: 16px;
      line-height: 24px;

      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 20px;
        right: 0;
      }

      @include media-breakpoint-down(md) {
        margin-bottom: 40px;
      }

      span {
        display: inline-block;
        font-weight: $regular;
        opacity: 0.5;

        &:first-child {
          opacity: 1;
        }

        &:nth-child(2) {
          margin: 0 3px;
        }
      }
    }

    .prog-bar-position {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }

  .right-col {
    position: static;
  }

  .imagegalleryrow {
    position: relative;

    &.grid-view {
      .gallery-mode {
        .viewmode {
          &.grid {
            display: none;
          }

          &.carousel {
            display: block;
          }
        }
      }

      .pagenum,
      .prog-bar-position {
        display: none;
      }

      .slick-track {
        width: 100% !important;
      }

      .image-col {
        @extend .col-3;
        width: 25% !important;
        margin-bottom: 30px;

        @include media-breakpoint-down(md) {
          width: 33.33% !important;
          max-width: 33.33%;
          @include flex(0 0 33.33%);
        }
      }

      .image-cnt {
        position: relative;

        &:hover {
          &:before {
            content: '';
            display: block;
            background: url('../app/images/gallery-ico2.svg') no-repeat;
            background-size: 28px 29px;
            width: 28px;
            height: 29px;
            position: absolute;
            top: 5px;
            right: 5px;
          }
        }

        img {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .gallery-mode {
      padding: 25px 0 0;

      .viewmode {
        position: relative;
        font-weight: $regular;
        line-height: 20px;
        display: table;

        &:hover {
          cursor: pointer;
        }

        &.grid {
          &:before {
            content: '';
            background: url('../app/images/gallery-ico.svg') no-repeat;
            background-size: 15px 15px;
            width: 15px;
            height: 15px;
            display: inline-block;
            margin-right: 15px;
            top: 2px;
            position: relative;
          }
        }

        &.carousel {
          display: none;

          &:before {
            content: '';
            background: url('../app/images/gallery-ico1.svg') no-repeat;
            background-size: 17px 12px;
            width: 17px;
            height: 12px;
            display: inline-block;
            margin-right: 15px;
            top: 2px;
            position: relative;
          }
        }
      }
    }
  }

  .image-col {
    outline: 0;
  }

  .image-gallery {
    position: static;

    .image-col {
      @include media-breakpoint-down(md) {
        margin-bottom: 0;
      }

      &.slick-slide {
        @include media-breakpoint-down(md) {
          padding: 0 8px;
        }
      }
    }

    .slick-slider {
      position: static;

      .slick-next,
      .slick-prev {
        top: 100px;
        left: 15px;
        right: auto;
        z-index: 2;
        @extend .social-ico;

        @include media-breakpoint-down(md) {
          top: 80px;
          left: auto;
          right: 15px;
        }

        &:before {
          content: '';
          display: block;
          background: url('../app/images/right-grey-arrow-ico.svg') no-repeat;
          background-size: 8px 14px;
          width: 8px;
          height: 14px;
        }
      }

      .slick-prev {
        @include media-breakpoint-down(md) {
          right: 69px;
        }

        &:before {
          @include transform(rotate(-180deg));
        }
      }

      .slick-next {
        left: 69px;

        @include media-breakpoint-down(md) {
          left: auto;
        }
      }
    }

    .slick-list {
      padding: 0 22% 0 0 !important;

      @include media-breakpoint-down(md) {
        padding-right: 15px !important;
      }
    }

    .slick-track {
      @include media-breakpoint-down(md) {
        padding-left: 7px;
      }
    }
  }

  .image-cnt {
    img {
      width: 100%;
    }
  }
}

.fancybox-container {
  .fancybox-toolbar {
    .fancybox-button--zoom,
    .fancybox-button--play,
    .fancybox-button--thumbs {
      display: none !important;
    }
  }

  .fancybox-navigation,
  .fancybox-infobar {
    display: none;
  }

  .fancybox-button--close {
    width: 44px;
    height: 44px;
    border: 1px solid $white;
    border-radius: 50%;
    position: absolute;
    top: 25px;
    right: 25px;
    opacity: 1;

    &:hover,
    &:active,
    &:focus {
      outline: 0;
    }

    &:after,
    &:before {
      position: absolute;
      left: 21px;
      top: 14px;
      content: ' ';
      height: 15px;
      width: 1px;
      background-color: $white;
    }

    &:before {
      @include transform(rotate(45deg))
    }

    &:after {
      @include transform(rotate(-45deg))
    }
  }

  .fancybox-bg {
    background: $black;
  }

  .fancybox-image {
    @include media-breakpoint-down(md) {
      padding: 15px;
    }
  }
}

body.compensate-for-scrollbar {
  overflow: visible;
  margin-right: 0;
}