// 3 Column Blue List

.component-3colbluelist {
  .col {
    @include flex(1);
  }

  .inside {
    height: 100%;
    padding: 40px 25px;
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(flex-start);

    h4 {
      margin-bottom: 25px;
      @include flex-grow(1);
    }

    h6 {
      margin-bottom: 0;
    }
  }

  .slick-list {
    padding: 0 15% 0 0 !important;
  }

  .slick-slide {
    &:focus {
      outline: 0;
    }
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    height: inherit;
  }
}