// Store Locator
.component-storelocator {
  .bh-sl-container {
    font-family: 'Noto Sans KR', sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: $blue;
    font-weight: $light;
    padding: 0;
    float: none;

    .form-group {
      margin: 0;
    }

    #bh-sl-submit {
      display: none;
    }

    .bh-sl-map-container {
      margin: 0;
      float: none;

      @include media-breakpoint-down(md) {
        padding: 0 15px;
      }
    }

    .bh-sl-loc-list,
    .bh-sl-map {
      height: 720px;
      padding: 0;

      @include media-breakpoint-down(md) {
        height: 550px;
      }

      @include media-breakpoint-down(sm) {
        height: 350px;
      }
    }

    .bh-sl-map {
      .gm-style {
        .gm-style-iw-c {
          border-radius: 0;
          border: 1px solid $grey;
          box-shadow: none;
          padding: 0;
          width: 100%;

          @include media-breakpoint-down(md) {
            min-width: 300px !important;
          }
        }

        .addr {
          font-size: 20px;
          line-height: 32px;
          color: rgba($black, 0.8);
          font-weight: $light;
          margin-bottom: 100px;
        }

        .gm-style-iw-d {
          padding: 50px;

          @include media-breakpoint-down(md) {
            padding: 15px;
          }
        }

        .gm-style-iw-d + button {
          top: 0 !important;
          right: 0 !important;

          img {
            min-width: 20px;
            min-height: 20px;
            margin: 0 !important;
          }
        }

        .extra-info {
          font-size: 16px;
          line-height: 28px;
          color: rgba($black, 0.8);
          font-weight: $light;

          @include media-breakpoint-down(md) {
            font-size: 14px;
            line-height: 24px;
          }

          h5 {
            color: $blue;
            font-weight: $bold;

            @include media-breakpoint-down(md) {
              font-size: 14px;
              line-height: 24px;
            }
            &+div {
              position: relative;
              &:before {
                content: '';
                background: url('../app/images/phone-ico.svg') no-repeat;
                width: 20px;
                height: 20px;
                position: relative;;
                display: inline-block;
                vertical-align: middle;
                margin-right: 5px;
              }
            }
          }
        }
      }
    }

    .bh-sl-loc-list {
      border: 1px solid $grey;

      .list {
        padding: 5px;

        li {
          border: none;

          &:not(.list-focus) {
            &:hover {
              .list-details {
                background: $whitesmoke;
              }
            }
          }
        }
      }

      .list-focus {
        border: none;

        .list-details {
          background: $blue;

          div {
            color: $white;
          }
        }
      }

      .list-details {
        float: none;
        margin: 0 0 20px;
        padding: 15px 20px;
        width: 100%;

        @include media-breakpoint-down(md) {
          margin: 0;
        }

        .list-content {
          padding: 0;
        }

        .loc-name {
          font-size: 16px;
          line-height: 24px;
          color: $blue;
          font-weight: $medium;

          @include media-breakpoint-down(md) {
            font-size: 14px;
            line-height: 24px;
          }
        }

        .loc-addr {
          font-size: 16px;
          line-height: 24px;
          color: $black;
          font-weight: $light;

          @include media-breakpoint-down(md) {
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
    }

    .map-info {
      max-width: 1370px;
      margin: 0 auto;

      .map-row {
        @include media-breakpoint-down(md) {
          @include flex-direction(column-reverse);
        }
      }
    }

    .reset-col {
      @include align-self(center);
    }

    .reset-all {
      font-size: 12px;
      line-height: 20px;
      color: $lightblue;
      font-weight: $regular;
      display: table;

      @include media-breakpoint-down(md) {
        margin: 0 auto;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .bh-sl-form-container {
    float: none;
    margin: 0 auto 80px;
    background: $whitesmoke;
    border-top: 1px solid $grey;

    @include media-breakpoint-down(md) {
      margin-bottom: 40px;
    }

    form {
      padding: 30px 0;

      input {
        width: 100%;
      }
    }

    .bh-sl-filters {
      float: none;
      margin: 0;
    }

    .bh-sl-filters-container {
      margin: 0;
      float: none;

      .filter-col {
        @include media-breakpoint-down(sm) {
          margin-bottom: 30px;
        }
      }
    }
  }
}