// Markets Carousel

.component-marketscarousel {
  margin-bottom: 80px;

  @include media-breakpoint-down(md) {
    margin-bottom: 60px;
  }

  .marketscnt {
    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }

  .market-item {
    position: relative;

    @include media-breakpoint-down(md) {
      @include flexbox();
      @include flex-direction(column-reverse);
    }

    .container {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 50%;
      @include transform(translateX(-50%));

      @include media-breakpoint-down(md) {
        position: relative;
        height: auto;
        top: auto;
        left: auto;
        padding: 0;
        @include transform(none);
      }
    }
  }

  .img-sct {
    position: relative;
    height: 200px;

    @include media-breakpoint-only(md) {
      height: 420px;
    }

    @include media-breakpoint-up(lg) {
      height: 720px;
      overflow: hidden;
      width: 100vw;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
    }

    &:after {
      content: '';
      display: block;
      background: rgba($blue, 0.4);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      min-width: 100%;
      min-height: 100%;
      object-fit: cover;
    }
  }

  .info-sct {
    position: relative;
    padding: 30px 15px 90px;

    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 50%;
      right: 0;
      padding: 40px 40px 90px;
      width: 400px;
      height: 500px;
      @include transform(translateY(-50%));
    }

    .inside {
      @include media-breakpoint-only(md) {
        max-width: 533px;
        margin: 0 auto;
      }
    }

    .tags {
      margin-bottom: 25px;
    }

    .cta {
      margin-top: 40px;

      @include media-breakpoint-down(md) {
        width: 100%;
        text-align: center;
        padding-left: 25px;
      }
    }
  }

  .slick-feature {
    position: relative;
  }

  .js-markets-slick {
    .slick-list {
      @include media-breakpoint-up(lg) {
        overflow: visible;
      }
    }
  }

  .slick-next,
  .slick-prev {
    top: 565px;
    left: auto;
    right: 115px;
    z-index: 2;
    background: transparent;
    border: 1px solid rgba($white, 0.5);
    @include transition(border 250ms ease-out);
    @extend .social-ico;

    @include media-breakpoint-down(md) {
      top: auto;
      bottom: 420px;
      right: 80px;
    }

    @include media-breakpoint-only(md) {
      right: 190px;
    }

    @include media-breakpoint-down(sm) {
      bottom: 200px;
    }

    &:before {
      content: '';
      display: block;
      background: url('../app/images/right-arrow-ico.svg') no-repeat;
      background-size: 8px 14px;
      width: 8px;
      height: 14px;
      @include transform(rotate(90deg));

      @include media-breakpoint-down(md) {
        @include transform(rotate(0));
      }
    }

    &:hover {
      border-color: $white;
      @include transition(border 250ms ease-out);
    }
  }

  .slick-prev {
    &:before {
      @include transform(rotate(-90deg));

      @include media-breakpoint-down(md) {
        @include transform(rotate(180deg));
      }
    }
  }

  .slick-next {
    right: 50px;

    @include media-breakpoint-down(md) {
      right: 15px;
    }

    @include media-breakpoint-only(md) {
      right: 120px;
    }
  }

  .slick-info {
    width: 400px;
    height: 90px;
    position: absolute;
    top: 520px;
    right: 0;
    color: $white;



    @include media-breakpoint-down(md) {
      width: calc(100% - 30px);
      height: 40px;
      top: auto;
      right: auto;
      bottom: 435px;
      left: 15px;
    }

    @include media-breakpoint-only(md) {
      left: 120px;
    }

    @include media-breakpoint-down(sm) {
      bottom: 210px;
    }

    .progress-bar {
      background-color: rgba($white, 0.3);
      background-image: linear-gradient(90deg, $white, $white);

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    .pagenum {
      font-size: 16px;
      line-height: 24px;

      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 50%;
        left: 40px;
        @include transform(translateY(-50%));
      }

      span {
        display: inline-block;
        font-weight: $regular;
        opacity: 0.5;

        &:first-child {
          opacity: 1;
        }

        &:nth-child(2) {
          margin: 0 3px;
        }
      }
    }
  }

  .subnav-index {
    position: absolute;
    top: 0;
    left: 45px;
    width: 550px;
    height: 100%;

    .markets-slider-nav {
      max-width: 550px;
      height: 100%;
      padding: 0;
      margin: 0;

      .slick-list {
        height: 100%;
      }

      .slick-track {
        max-width: 550px;
        height: 100%;
        @include flexbox();
        @include flex-direction(column);
        @include justify-content(center);
      }

      .market-item-index {
        opacity: 1 !important;
        height: auto !important;
        left: auto !important;
        float: none;
        font-size: 40px;
        line-height: 52px;
        font-weight: $light;
        color: rgba($white, 0.5);
        margin-bottom: 15px;

        &:lasts-child {
          margin-bottom: 0;
        }

        &.slick-current {
          color: $white;
        }

        &:hover,
        &:focus,
        &:active {
          outline: 0;
          border: 0;
          box-shadow: none;
          cursor: pointer;
        }
      }
    }
  }
}