// Hero Panel
.component-heropanel {
  background: $white;

  &.bluebg {
    background: $blue;

    .breadcrumbs {
      * {
        color: $white;
      }

      li {
        &:after {
          color: $white;
        }
      }
    }
  }

  .inside {
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(flex-end);
    height: 1px;
    min-height: 380px;
    padding: 10px 0;

    @include media-breakpoint-down(sm) {
      min-height: 280px;
    }
  }

  .display1,
  .display4 {
    text-transform: uppercase;
  }

  .display1 {
    margin-bottom: 50px;
    @include media-breakpoint-up(lg) {
      max-width: 905px;
    }
  }

  .breadcrumbs {
    * {
      color: $blue;
    }

    li {
      &:after {
        color: $blue;
      }
    }
  }
}