// coverage map

.component-coveragemap {
  .map-area {
    border: 1px solid $grey;

    @include media-breakpoint-down(md) {
      border: 0;
      @include flexbox();
      @include flex-direction(column-reverse);
    }

    &.mob-active {
      .map-menu {
        .first-level {
          > a {
            font-size: 21px;
            line-height: 1;
            font-weight: $light;
            padding-bottom: 30px;
          }

          &:after {
            display: none;
          }
        }
      }

      .choose-region {
        display: none;
      }

      .change-region {
        display: block;
      }
    }

    &.final-map-step {
      .map-menu,
      .change-region {
        display: none;
      }

      .change-region-back {
        display: block;
      }
    }
  }

  img {
    width: 100%;
    height: auto;
  }

  .map-menu,
  .map-second {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
    @include flexbox();

    li {
      font-size: 16px;
      line-height: 24px;
      color: $blue;
      font-weight: $regular;
      padding: 0 40px;

      @include media-breakpoint-down(md) {
        font-size: 14px;
        line-height: 20px;
        border-bottom: 1px solid $grey;
      }

      a {
        color: inherit;
        padding: 20px 0;
        display: block;
        position: relative;

        &:after {
          content: '';
          display: none;
          width: 100%;
          height: 2px;
          background: $blue;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
    }
  }

  .map-menu {
    position: relative;
    border-bottom: 1px solid $grey;
    z-index: 1;

    @include media-breakpoint-down(md) {
      @include flex-direction(column);
      border: 0;
    }

    .first-level {
      @include media-breakpoint-down(md) {
        padding: 0;
        position: relative;

        &.mob-hidden {
          display: none;
        }

        &:after {
          content: '';
          display: block;
          background: url('../app/images/right-grey-arrow-ico.svg') no-repeat;
          background-position: center center;
          width: 8px;
          height: 14px;
          background-size: 8px 14px;
          position: absolute;
          top: 50%;
          right: 10px;
          @include transform(translate(-50%, -50%));
        }
      }

      > a {
        &:hover,
        &.active {
          text-decoration: none;

          @include media-breakpoint-up(md) {
            &:after {
              display: block;
            }
          }
        }
      }
    }
  }

  .choose-region {
    font-size: 10px;
    line-height: 20px;
    color: $blue;
    font-weight: $medium;
    letter-spacing: 2.2px;
    opacity: 0.7;
    padding: 20px 0;
    border-bottom: 1px solid $grey;
  }

  .change-region,
  .change-region-back {
    display: none;
    font-size: 14px;
    line-height: 20px;
    background: $blue;
    color: $white;
    letter-spacing: 0.5px;
    padding: 20px 55px;
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-right: -15px;
    position: relative;

    &:after {
      content: '';
      display: block;
      background: url('../app/images/right-arrow-ico.svg') no-repeat;
      background-position: center center;
      width: 8px;
      height: 14px;
      background-size: 8px 14px;
      position: absolute;
      top: 50%;
      left: 25px;
      @include transform(translate(-50%, -50%) rotate(180deg));
    }
  }

  .map-second {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin: 0;
    margin-top: 65px;
    background: $white;
    border-bottom: 1px solid $grey;

    @include media-breakpoint-down(md) {
      position: relative;
      top: auto;
      left: auto;
      margin: 0;
      border: 0;
      @include flex-direction(column);
    }

    &:after {
      content: '';
      display: block;
      background: $white;
      width: 100%;
      height: 20px;
      position: absolute;
      bottom: -21px;
      left: 0;
    }

    &.active {
      @include flexbox();
    }

    li {
      font-size: 13px;
      line-height: 20px;
      padding: 0 17px;

      @include media-breakpoint-down(md) {
        padding: 0;
        border: 0;
      }

      &:first-child {
        padding-left: 40px;

        @include media-breakpoint-down(md) {
          padding: 0;
        }
      }

      &:last-child {
        padding-right: 40px;

        @include media-breakpoint-down(md) {
          padding: 0;
        }
      }

      a {
        @include media-breakpoint-down(md) {
          padding: 0 0 20px;
        }

        &:hover,
        &.active {
          text-decoration: none;

          @include media-breakpoint-up(md) {
            &:after {
              display: block;
            }
          }
        }
      }
    }
  }

  .map-container {
    padding: 20px;

    @include media-breakpoint-down(md) {
      padding: 0;
    }

    .map {
      margin: 0 auto;
      display: table;
      position: relative;

      @include media-breakpoint-down(md) {
        width: calc(100% + 30px);
        margin-left: -15px;
        margin-right: -15px;
      }

      &.active {
        display: none;
      }

      .map-item {
        position: absolute;
        top: 0;
        left: 0;
        display: none;

        &.active {
          display: block;
        }
      }
    }
  }

  .map-title {
    display: none;

    &.activat {
      display: block;
    }
  }

  .small-map {
    max-width: 100%;
    margin: 90px auto 0;
    display: none;

    @include media-breakpoint-down(md) {
      margin: 0 auto;
    }

    &.active {
      display: block;
    }

    .info-col {
      @include media-breakpoint-down(md) {
        padding: 25px 0 0;
      }
    }

    > .container {
      > .row {
        @include media-breakpoint-down(md) {
          @include flex-direction(column-reverse);
        }
      }
    }

    .map-col {
      @include media-breakpoint-down(md) {
        padding: 0;
        width: calc(100% + 30px);
        margin-left: -15px;
        margin-right: -15px;
      }

      &.extralevel {
        span {
          top: 80px;
        }

        .map-cnt {
          display: none;

          &.activat {
            display: table;
          }
        }
      }

      .row-map-selector {
        position: absolute;
        top: 20px;
        left: 15px;
        margin: 0;
        width: calc(100% - 30px);
        z-index: 2;

        .mapselector {
          font-size: 12px;
          line-height: 12px;
          color: $blue;
          background: transparent;
          padding: 15px;
          border-radius: 22px;
          text-align: center;
          border: 1px solid $blue;
          display: block;

          &.activat {
            background: $purple;
            border-color: $purple;
            color: $white;
          }

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    .map-cnt {
      position: relative;
      width: 100%;

      &.active {
        span {
          display: block;
          z-index: 2;
        }

        .sm-map-item {
          opacity: 0.15;

          &.active {
            opacity: 1;
          }
        }
      }

      .sm-map-item {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }

      span {
        font-size: 12px;
        line-height: 12px;
        font-weight: $regular;
        color: $blue;
        position: absolute;
        top: 20px;
        left: 20px;
        background: $integrey;
        border-radius: 22px;
        padding: 16px;
        text-align: center;
        width: calc(100% - 40px);
        display: none;
      }
    }

    .map-headline {
      font-size: 48px;
      line-height: 64px;
      color: $blue;
      font-weight: $light;
      letter-spacing: -0.4px;

      @include media-breakpoint-down(md) {
        font-size: 21px;
        line-height: 1;
      }
    }

    .map-region {
      font-size: 20px;
      line-height: 32px;
      color: $blue;
      font-weight: $light;
      letter-spacing: -0.3px;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    .info-table {
      margin: 50px 0;

      .coverimage {
        &:hover {
          cursor: pointer;
          background: $whitesmoke;
        }
      }

      .col {
        font-size: 15px;
        line-height: 20px;
        color: $blue;
        font-weight: $light;
        padding: 10px;

        @include media-breakpoint-down(md) {
          font-size: 12px;
          line-height: 16px;
          padding: 10px;
          word-break: break-all;
        }

        &.col-head {
          font-weight: $regular;
        }
      }

      .icol {
        width: 84px;
        height: 4px;
        display: block;
        margin-top: 8px;

        @include media-breakpoint-down(md) {
          width: 100%;
        }

        &.i1 {
          background: $map-color-i1;
        }

        &.i2 {
          background: $map-color-i2;
        }

        &.i3 {
          background: $map-color-i3;
          border: 1px solid $black;
        }

        &.i4 {
          background: $map-color-i4;
        }

        &.i5 {
          background: $map-color-i5;
        }

        &.i6 {
          background: $map-color-i6;
        }

        &.i7 {
          background: $map-color-i7;
        }

        &.i8 {
          background: $map-color-i8;
        }

        &.i9 {
          background: $map-color-i9;
        }

        &.i10 {
          background: $map-color-i10;
        }
      }
    }

    .map-disclaimer {
      font-size: 14px;
      line-height: 24px;
      font-weight: $light;
    }
  }
} #disclosureTable table td {padding: 9px 10px;border: 1px solid #dbdbdb;text-align: center;} #disclosureTable table td a{text-decoration: none;color: #333333;} #disclosureTable table th {border: 1px solid #000f36; background-color: #000f36;color: white;text-align: center !important;padding: 9px 10px;} .component-richtext .editor #disclosureTable{max-width: none !important;} #disclosureTable table{max-width: none !important;width: -webkit-fill-available;} #disclosureTable table a:hover {text-decoration: underline;} .financeTable table td {padding: 9px 10px;border: 1px solid #dbdbdb;text-align: right;} .financeTable table .highlight{font-weight:bold; color:#0047ba; background-color:#f7f7f7;} .financeTable table th {border: 1px solid #dbdbdb;background-color: #f3f3f3;text-align: center !important;padding: 9px 10px; width:200px; font-weight: normal; text-align:left !important;} .component-richtext .editor .financeTable{max-width: none !important;} .financeTable table{max-width: none !important;width: -webkit-fill-available;} .financeTable table tr:hover{background-color: #f3f3f3}.row.paginationsct {margin-top:20px;} .col-lg-4.sitemap-col {max-width: 66.6%;} .component-productdetail .row.productdetailrow a.cta.keyline{float:left;margin-right:10px;}