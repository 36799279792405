// Feature Panel component
.component-featurepanel {
  margin-bottom: 145px;

  @include media-breakpoint-down(md) {
    margin-bottom: 85px;
  }

  &.gray-theme {
    margin-bottom: 0;
    padding-top: 80px;
    padding-bottom: 80px;
    background: $whitesmoke;

    @include media-breakpoint-down(md) {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }

  .featurepanel {
    padding: 50px;

    @include media-breakpoint-down(md) {
      padding: 30px 25px;
    }

    &.light-blue {
      background: $purple;
    }

    &.white {
      background: $whitesmoke;

      * {
        color: $blue;
      }

      .cta {
        @extend .keyline;
      }
    }
  }

  .row-info {
    margin-bottom: 20px;

    @include media-breakpoint-down(md) {
      margin-bottom: 30px;
    }

    .inside {
      @include media-breakpoint-down(md) {
        max-width: 533px;
        margin: 0 auto;
      }

      p {
        @include media-breakpoint-down(sm) {
          font-size: 14px;
          line-height: 24px;
          letter-spacing: .1px;
        }
      }
    }
  }

  .row-cta {
    .cta {
      float: right;

      @include media-breakpoint-down(md) {
        float: none;
        width: 100%;
        max-width: 533px;
        margin: 0 auto;
        text-align: center;
        padding-left: 25px;
      }
    }
  }
}