// Image and Text component
.component-text-image,
.component-figure-image {
  .imagetxt,
  .figuretxt {
    @include media-breakpoint-down(md) {
      max-width: 533px;
      margin: 0 auto;
    }
  }

  .imagetxtrow,
  .figuretxtrow {
    @include align-items(flex-start);

    .inside {
      max-width: 325px;

      @include media-breakpoint-down(md) {
        max-width: 100%;
      }
    }

    .col {
      &.left-col {
        @include media-breakpoint-down(md) {
          margin-bottom: 65px;
        }
      }
    }

    .img-cnt {
      margin-bottom: 40px;

      @include media-breakpoint-down(md) {
        margin-bottom: 25px;
      }
    }

    .figure-cnt {
      font-size: 96px;
      line-height: 152px;
      letter-spacing: -1.92px;
      font-weight: $thin;
      color: $lighterblue;
      margin-bottom: 15px;

      @include media-breakpoint-down(lg) {
        font-size: 72px;
        line-height: 96px;
        letter-spacing: -1.44px;
      }
    }
  }
}

.component-text-image {
  margin-top: 80px;

  @include media-breakpoint-down(md) {
    margin-top: 60px;
  }

  &.multicol {
    .col {
      @include media-breakpoint-down(md) {
        margin-bottom: 50px;

        &:last-of-type {
          margin: 0;
        }
      }
    }

    .inside {
      max-width: 100%;
    }
  }
}