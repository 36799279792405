// 3 column feature panel
.component-colfeaturepanel {
  padding: 80px 0;
  background: $whitesmoke;

  @include media-breakpoint-down(md) {
    padding: 60px 0;
  }

  .featurecol {
    @include flex(1);

    @include media-breakpoint-down(md) {
      margin-bottom: 30px;
      @include flex(none);

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .info-sct {
      height: 100%;
      padding: 40px;
      background: $white;
      @include flexbox();
      @include flex-direction(column);
      @include justify-content(flex-end);

      @include media-breakpoint-only(md) {
        max-width: 50%;
        @include flex(0 0 50%);
      }

      @include media-breakpoint-down(md) {
        padding: 25px;
      }
    }

    .featurecol-link {
      height: 100%;
      text-decoration: none;
      color: inherit;
      position: relative;
      @include flexbox();
      @include flex-direction(column);
      @include justify-content(flex-start);

      @include media-breakpoint-only(md) {
        @include flex-direction(row);
      }

      &:after {
        content: "";
        display: block;
        width: 0;
        height: 3px;
        background: $blue;
        position: absolute;
        left: 0;
        bottom: 0;
        @include transition(all .25s ease);
      }

      &:hover {
        @include media-breakpoint-up(lg) {
          &:after {
            width: 100%;
            @include transition(all .25s ease);
          }

          .cta {
            &:before {
              background: url(../app/images/right-long-grey-arrow-ico.svg) no-repeat;
              background-size: 15px 11px;
              width: 15px;
              height: 11px;
            }
          }
        }
      }
    }

    h3 {
      @include flex-grow(1);
    }

    .tags {
      margin-bottom: 15px;
    }

    .cta-sect {
      margin-top: 50px;

      .cta {
        @include media-breakpoint-down(md) {
          width: 100%;
          text-align: center;
          padding-left: 25px;
        }

        &:hover {
          &:before {
            background: url(../app/images/right-grey-arrow-ico.svg) no-repeat;
            background-size: 7px 11px;
            width: 7px;
            height: 11px;
          }
        }
      }
    }

    .img-cnt {
      @include media-breakpoint-only(md) {
        max-width: 50%;
        @include flex(0 0 50%);
      }

      img {
        @include media-breakpoint-down(md) {
          width: 100%;
        }
      }
    }
  }
}