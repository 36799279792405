// 2 column recommended component
.component-2colrecontent {
  .inside-img {
    margin-bottom: 30px;

    @include media-breakpoint-down(md) {
      margin-bottom: 25px;
    }

    img {
      width: 100%;
    }
  }

  .col {
    &:first-of-type {
      @include media-breakpoint-down(md) {
        margin-bottom: 55px;
      }
    }
  }
}