// 2 column recommended component
.component-3colrecontent {
  .inside-img {
    margin-bottom: 20px;
    background: $whitesmoke;

    img {
      width: 100%;
    }
  }

  .col {
    @include media-breakpoint-down(md) {
      margin-bottom: 40px;
    }

    &:last-of-type {
      @include media-breakpoint-down(md) {
        margin-bottom: 0;
      }
    }
  }
}