// Compare products
.component-compare-products {
  margin-bottom: 80px;

  @include media-breakpoint-down(md) {
    margin-bottom: 65px;
  }

  .select-row {
    margin-bottom: 30px;

    .form-group {
      margin: 0;
    }
  }

  .img-sct {
    background: $whitesmoke;
  }

  .label-row {
    h3 {
      border-bottom: 1px solid $grey;
      padding-bottom: 15px;
      margin-bottom: 30px;

      @include media-breakpoint-down(md) {
        font-size: 20px;
        line-height: 32px;
      }
    }
  }

  .info-row {
    margin-bottom: 80px;

    @include media-breakpoint-down(md) {
      margin-bottom: 55px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    .pinfo {
      margin-bottom: 0;

      @include media-breakpoint-down(md) {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }

  .image-row {
    margin-bottom: 70px;
  }

  .pinfo {
    display: none;

    &.active {
      display: block;
    }
  }
}