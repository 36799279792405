// Article Template
.news-template {
  padding: 25px 0;

  @include media-breakpoint-down(md) {
    padding: 0;
  }

  .breadcrumbs {
    @include media-breakpoint-down(md) {
      display: none;
    }

    * {
      color: $blue;
    }

    li:after {
      color: $blue;
    }
  }

  .gotoprev {
    color: $black;

    a {
      color: inherit;
      position: relative;

      &:before {
        content: '';
        display: inline-block;
        background: url(../app/images/media-ico.svg) no-repeat;
        background-size: 14px 14px;
        width: 14px;
        height: 14px;
        margin-right: 15px;
        top: 2px;
        position: relative;
      }
    }
  }

  .article-cnt {
    padding: 80px 0;

    @include media-breakpoint-down(md) {
      padding: 60px 0;
    }

    p {
      img {
        max-width: 100%;
        height: auto;
        margin: 25px auto 40px;
      }
    }

    .main-article {
      .extranews {
        margin: 0 auto;
      }

      h3 {
        color: rgba($black, 0.6);
      }

      .cta + h3,
      p + .cta {
        margin-top: 40px;
      }

      .cta {
        @include media-breakpoint-down(md) {
          width: 100%;
          text-align: center;
          padding-left: 25px;
        }
      }

      .extrainfo {
        padding-top: 15px;
        padding-bottom: 40px;

        .inside {
          padding: 3px 15px;
          border-left: 1px solid $grey;
        }

        .dates {
          font-size: 14px;
          line-height: 20px;
          opacity: 0.9;
        }

        .extracol {
          @include media-breakpoint-down(md) {
            margin-bottom: 30px;

            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }

      .social-sct {
        margin-bottom: 55px;

        .tags {
          margin-bottom: 10px;
        }

        .social-section {
          padding: 0;
          margin: 0;
          list-style-type: none;
          @include flexbox();

          li {
            margin-right: 15px;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    .main-article + section {
      margin-top: 140px;

      @include media-breakpoint-down(md) {
        margin-top: 65px;
      }
    }

    section {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}