footer {
  .primary-footer {
    padding: 60px 0 85px; 
    background: $white;
    position: relative;

    @include media-breakpoint-down(md) {
      padding: 0;
    }

    &:before {
      content: '';
      display: block;
      background: rgba($black, 0.1);
      width: calc(100% - 50px);
      height: 1px;
      position: absolute;
      left: 25px;
      top: 0;

      @include media-breakpoint-down(md) {
        width: calc(100% - 30px);
        left: 15px;
      }
    }

    h3 {
      font-size: 14px;
      line-height: 24px;
      font-weight: $medium;
      color: $blue;
      margin-bottom: 10px;

      @include media-breakpoint-down(md) {
        margin-bottom: 20px;
      }
    }

    .footer-col {
      &.social-block {
        @include media-breakpoint-down(md) {
          padding: 60px 15px;
        }

        .cta {
          @include media-breakpoint-down(md) {
            width: 100%;
            text-align: center;
            padding-left: 25px;
          }
        }
      }

      &:not(.social-block) {
        h3 {
          @include media-breakpoint-down(md) {
            font-size: 16px;
            line-height: 26px;
            letter-spacing: 0.16px;
            font-weight: $light;
            position: relative;
            margin-bottom: 0;
            padding: 20px 0;
            border-bottom: 1px solid $grey;

            &.active {
              border-bottom: 1px solid transparent;
            }
          }

          .crossico {
            display: none;

            @include media-breakpoint-down(md) {
              display: block;
            }
          }
        }
      }
    }

    ul {
      font-size: 14px;
      line-height: 20px;
      color: $black;
      letter-spacing: -0.09px;
      font-weight: $light;
      list-style-type: none;
      padding: 0;
      margin: 0 0 40px 0;

      &:not(.social-section) {
        @include media-breakpoint-down(md) {
          display: none;
          padding: 10px 0 30px;
          margin-bottom: 0;
          border-bottom: 1px solid $grey;

          li {
            padding: 8px 0;
          }
        }

        &.active {
          @include media-breakpoint-down(md) {
            display: block;
          }
        }
      }

      li {
        padding: 2px 0;

        a {
          color: inherit;
        }
      }
    }

    .social-section {
      @include flexbox();
      @include flex-wrap(wrap);
      width: calc(100% + 16px);
      max-width: 195px;
      margin-left: -8px;
      margin-right: -8px;

      @include media-breakpoint-down(md) {
        max-width: calc(100% + 16px);
      }

      li {
        padding: 0 8px;
        margin: 0 0 16px;
      }
    }
  }

  .secondary-footer {
    font-size: 12px;
    line-height: 20px;
    font-weight: $light;

    .right-section {
      ul {
        @include media-breakpoint-down(md) {
          height: 110px;
          margin: 30px 0;
          @include flex-direction(column);
          @include flex-wrap(wrap);

          li {
            padding: 8px 0;
          }
        }
      }
    }

    .left-section {
      @include media-breakpoint-down(md) {
        ul {
          margin-bottom: 20px;
          @include flex-direction(column);
        }
      }

      li {
        &:first-child {
          padding-left: 0;
        }
      }
    }

    .sec-row {
      @include media-breakpoint-down(md) {
        @include flex-direction(column-reverse);
      }
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      @include flexbox();

      li {
        padding: 16px 10px;

        @include media-breakpoint-down(md) {
          padding: 2px 0;
        }
      }
    }
  }
}
