// Main search component

.component-productinfo {
  margin-bottom: 80px;

  @include media-breakpoint-down(md) {
    margin-bottom: 65px;
  }

  .minititle {
    @include media-breakpoint-up(lg) {
      margin-bottom: 50px;
    }
  }

  .info-row {
    @include media-breakpoint-up(lg) {
      margin-bottom: 30px;
      padding-bottom: 30px;
      position: relative;

      &:after {
        content: '';
        display: block;
        width: calc(100% - 30px);
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 15px;
        background: $grey;
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .extrainfo {
    @include media-breakpoint-down(md) {
      margin-bottom: 30px;
    }
  }

  .cta {
    @include media-breakpoint-down(md) {
      width: 100%;
      text-align: center;
      padding-left: 25px;
    }
  }
}