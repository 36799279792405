// Rich text component
.component-richtext {
  margin-top: 80px;

  @include media-breakpoint-down(md) {
    margin-top: 60px;
  }

  .greybg {
    .editor {
      background: $whitesmoke;
      padding: 40px;
      max-width: 770px;

      @include media-breakpoint-down(md) {
        max-width: 533px;
        margin: 0 auto;
      }

      @include media-breakpoint-down(sm) {
        padding: 25px;
      }
    }
  }

  .editor {
    @include media-breakpoint-down(md) {
      max-width: 533px;
      margin: 0 auto;
    }

    .cta {
      margin-bottom: 15px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    img {
      max-width: 100%;
      height: auto;
    }

    * {
      @include media-breakpoint-up(lg) {
        max-width: 670px;
      }
    }

    h1 {
      @include media-breakpoint-up(lg) {
        max-width: 770px;
      }
    }

    .img-full {
      @include media-breakpoint-up(lg) {
        max-width: 100%;
      }
    }

    p {
      &:not(:first-child),
      &:not(:only-child), {
        &.img-full {
          margin: 40px auto;

          @include media-breakpoint-down(md) {
            margin: 15px auto;
          }
        }
      }

      &:last-child {
        &:not(:only-child) {
          &.img-full {
            margin: 40px auto 0;

            @include media-breakpoint-down(md) {
              margin: 15px auto 0;
            }
          }
        }
      }
    }
  }
}