html {
  box-sizing: border-box;
  font-size: 100%;
}

*, *:before, *:after {
  box-sizing: inherit;   
}

body {
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 16px;
  line-height: 28px;
  font-weight: $light;
  letter-spacing: 0.2px;
  color: $black;
  background: $white;
  overflow-x: hidden;

  .main {
    padding-top: 117px;
    overflow: hidden;
    @include media-breakpoint-down(md) {
      padding-top: 74px;
    }
  }
}

.container {
  max-width: $containermax;
}

.nospace {
  margin: 0;
}

.hidden-mob {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.hidden-dkt {
  display: none;

  @include media-breakpoint-down(md) {
    display: block;
  }
}

.hidden-dkt-small {
  display: none;

  @include media-breakpoint-down(sm) {
    display: block;
  }
}
// delete after demmo
.demmo {
  padding: 50px 0;

  .demmo-col {
    padding-top: 120px;
    padding-bottom: 120px;

    .inside {
      max-width: 440px;
      margin: 0 auto;
    }
  }

  .displayfonts,
  .richeditor {
    margin-bottom: 100px;
  }

  .displayfonts,
  .richeditor,
  .last-section {
    * {
      margin-bottom: 30px;
    }
  }
}

section {
  &.component {
    margin-bottom: 80px;

    @include media-breakpoint-down(md) {
      margin-bottom: 60px;
    }

    &.component-4colcardlinks {
      margin-bottom: 0;
    }
  }

  &.component-4colcardlist {
    margin-bottom: 20px;

    @include media-breakpoint-down(md) {
      margin-bottom: 0;
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 60px;
    }
  }

  &.component-3colrichtext {
    .right-col {
      @include media-breakpoint-down(md) {
        max-width: 563px;
        margin: 0 auto;
      }
    }

    .col {
      &:not(:last-of-type) {
        @include media-breakpoint-down(sm) {
          margin-bottom: 50px;
        }
      }
    }
  }

  &.component-page-error {
    margin: 0;
  }
}

// Fake select
.dropdown {
  &.fakeselect {
    @include media-breakpoint-down(md) {
      margin-bottom: 30px;
    }

    .dropdown-menu {
      width: 100%;
      border-top: 0;
      border-radius: 0;
      margin: 0;
      z-index: 3;
    }

    .dropdown-item {
      font-size: 14px;
      line-height: 20px;
      font-weight: 300;
      padding: 10px 25px;
      width: auto;

      &:hover {
        background-color: $whitesmoke;
        color: $lightblue;
        text-decoration: underline;
      }
    }

    .scrollbar-inner {
      width: 100%;
      height: 160px;
      overflow: auto;
    }

    button {
      &.dropdown-toggle {
        font-size: 14px;
        line-height: 20px;
        color: $black;
        font-weight: $light;
        text-align: left;
        background: $white;
        border: 1px solid $grey;
        border-radius: 0;
        padding: 17px 25px;
        outline: 0;
        width: 100%;
        min-width: 100%;

        &:after {
          background: url('../app/images/down-arrow-blue-ico.svg') no-repeat;
          background-size: 10px 6px;
          width: 10px;
          height: 6px;
          border: 0;
          position: absolute;
          right: 25px;
          top: 50%;
          @include transform(translateY(-50%));
          @include transition(transform 0.15s ease);
        }

        &:hover,
        &:focus,
        &:active,
        &:active:focus {
          outline: 0;
          color: $black;
          background: $white;
          box-shadow: none;
          border-color: $grey;
        }

        &.show,
        &[aria-expanded="true"] {
          &:after {
            @include transform(translateY(-50%) rotate(-180deg));
            @include transition(transform 0.15s ease);
          }
        }
      }
    }
  }
}

/* fade image in after load */
.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  @include transition(opacity 250ms);
}

// back to top CTA
.js-back-to-top {
  position: fixed;
  bottom: 25px;
  right: 25px;
  background-color: $emerald;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  z-index: 3;
  pointer-events: none;
  cursor: not-allowed;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  @include transition(transform 0.25s ease);

  @include media-breakpoint-down(md) {
    display: none;
  }

  &:after {
    content: '';
    display: block;
    background: url('../app/images/backtotop-ico.svg') no-repeat;
    background-size: 16px 12px;
    width: 16px;
    height: 16px;
  }

  &.rotated {
    pointer-events: all;
    cursor: pointer;
    @include transform(rotate(180deg));
    @include transition(transform 0.25s ease);
  }
}

// Video iframe popup
.modal-open {
  padding: 0 !important;
  overflow: visible;
}

.modal {
  &.show {
    background: rgba($black, 0.9);
    padding: 0 !important;
  }

  &.fade {
    .modal-dialog {
      top: 50%;
      @include transform(translateY(-50%));
      max-width: 1136px;
    }
  }

  .modal-content {
    border: 0;
    border-radius: 0;
    background-color: transparent;
  }

  .close {
    width: 44px;
    height: 44px;
    border: 1px solid $white;
    border-radius: 50%;
    position: absolute;
    top: 25px;
    right: 25px;
    opacity: 1;

    &:hover,
    &:active,
    &:focus {
      outline: 0;
    }

    &:after,
    &:before {
      position: absolute;
      left: 21px;
      top: 14px;
      content: ' ';
      height: 15px;
      width: 1px;
      background-color: $white;
    }

    &:before {
      @include transform(rotate(45deg))
    }

    &:after {
      @include transform(rotate(-45deg))
    }
  }
}