// Header style
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 117px;
  z-index: 4;
  @include transition(all 0.25s ease-in-out);

  @include media-breakpoint-down(md) {
    height: 74px;
  }

  &.nav-up {
    height: 77px;
    margin-top: -40px;
    @include transition(all 0.25s ease-in-out);

    @include media-breakpoint-down(md) {
      margin-top: 0;
      height: 74px;
    }
  }
  // multiple header templates
  &:not(.nav-up):not(.nav-down) {
    .navbar-primary {
      @include media-breakpoint-up(lg) {
        &.solid-blue {
          background: $blue;
          border-bottom-color: rgba($white, 0.2);
          @include transition(background 0.25s ease-in-out);
        }

        &.transparent-positive,
        &.transparent-negative {
          background: transparent;
          border-bottom-color: rgba($white, 0.2);
          @include transition(background 0.25s ease-in-out);
        }

        &.solid-blue,
        &.transparent-negative {
          svg {
            polygon,
            path,
            rect {
              fill: $white;
              @include transition(fill 0.25s ease-in-out);
            }
          }

          .menu-list-primary {
            .menu-sct {
              > li {
                color: $white;

                > a:not(.cta):after {
                  background: $white;
                }
              }
            }
          }
        }

        &.transparent-positive {
          svg {
            polygon,
            path,
            rect {
              fill: $blue;
              @include transition(fill 0.25s ease-in-out);
            }
          }

          .menu-list-primary {
            .menu-sct {
              > li {
                color: $blue;

                > a:not(.cta):after {
                  background: $blue;
                }
              }
            }
          }
        }
      }
    }

    .mobile-menu {
      &.solid-blue,
      &.transparent-negative {
        .js-nav-toggle {
          span {
            background: $white;
          }
        }

        svg {
          polygon,
          path,
          rect {
            fill: $white;
            @include transition(fill 0.25s ease-in-out);
          }
        }

        .language-switch-mobile {
          svg {
            fill: $white;
            @include transition(fill 0.25s ease-in-out);
          }
        }

        &.white {
          .js-nav-toggle {
            span {
              background: $blue;
            }
          }

          svg {
            polygon,
            path,
            rect {
              fill: $blue;
              @include transition(fill 0.25s ease-in-out);
            }
          }

          .language-switch-mobile {
            svg {
              fill: $blue;
              @include transition(fill 0.25s ease-in-out);
            }
          }
        }
      }

      &.solid-blue {
        background: $blue;
        border-bottom-color: rgba($white, 0.2);
        @include transition(background 0.25s ease-in-out);
      }

      &.transparent-positive {
        .js-nav-toggle {
          span {
            background: $blue;
          }
        }

        svg {
          polygon,
          path,
          rect {
            fill: $blue;
            @include transition(fill 0.25s ease-in-out);
          }
        }
        .language-switch-mobile {
          svg {
            fill: $blue;
            @include transition(fill 0.25s ease-in-out);
          }
        }
      }

      &.transparent-positive,
      &.transparent-negative {
        background: transparent;
        border-bottom-color: rgba($white, 0.2);
        @include transition(background 0.25s ease-in-out);

        &.white {
          background: $white;
          border-color: transparent;
          @include transition(background 0.25s ease-in-out);
        }
      }
    }
  }

  &.open {
    .navbar-db {
      @include flexbox();
    }

    .mobile-menu {
      border-color: transparent;
    }

    .js-nav-toggle {
      span {
        &:first-of-type {
          top: 6px;
          left: -2px;
          width: 20px;
          @include transform(rotate(45deg));
        }

        &:nth-child(2) {
          width: 0%;
          opacity: 0;
        }

        &:last-of-type {
          top: 6px;
          left: -2px;
          width: 20px;
          @include transform(rotate(-45deg));
        }
      }
    }
  }

  &.active-menu {
    .menu-list-primary {
      .menu-sct {
        > li:not(.active-el):not(.home-link) {
          display: none;
        }

        .active-el {
          padding: 0;
          border: 0;

          &.multilevel {
            > a {
              font-size: 21px;
              line-height: 26px;
              font-weight: $light;
              display: block;
              padding: 30px 0;
              border-bottom: 1px solid $grey;
            }
          }

          > a {
            display: none;
          }

          .dropdown-content {
            display: block;
            position: relative;
            margin: 0;
            border: 0;
            min-height: auto;

            .sbm-cnt {
              padding: 0;

              h3 {
                padding: 30px 0;
                margin: 0;
              }
            }

            .mob-inv {
              flex-direction: column-reverse;

              .rgt-sect {
                li {
                  &:first-child {
                    border-top: 0;
                  }
                }
              }

              .rgt-sect,
              .lft-sect {
                li {
                  &:last-child {
                    border-bottom: 1px solid $grey;
                  }
                }
              }

              .cta {
                width: 100%;
                margin: 20px auto;
                text-align: center;

                @include media-breakpoint-down(md) {
                  padding-left: 0;
                }

                &:before {
                  @include media-breakpoint-down(md) {
                    background: url('../app/images/right-long-arrow-ico.svg') no-repeat;
                    background-size: 15px 11px;
                    width: 15px;
                    height: 11px;
                    display: inline-block;
                    position: relative;
                    left: auto;
                    @include transform(none);
                    margin-right: 20px;
                  }
                }
              }
            }
          }
        }

        .home-link {
          background: $blue;
          color: $white;
          position: relative;
          padding-left: 0;
          border-bottom: 1px transparent;

          &:after,
          &:before {
            content: '';
            display: block;
            width: 15px;
            height: 100%;
            background: $blue;
            position: absolute;
            top: 0;
            left: -15px;
            @include transform(none);
          }

          &:after {
            left: auto;
            right: -15px;
          }

          a {
            position: relative;
            padding-left: 25px;

            &:before {
              content: '';
              display: block;
              background: url('../app/images/left-white-arrow-ico.svg') no-repeat;
              width: 8px;
              height: 14px;
              position: absolute;
              top: 50%;
              left: 7px;
              @include transform(translate(-50%, -50%));
            }
          }
        }
      }
    }

    .navbar-secondary {
      display: none;
    }
  }

  &.m1 {
    .menu-sct {
      .multilevel {
        &:after {
          display: none;
        }
      }
    }

    .deep-menu {
      &.step2 {
        li {
          display: none;

          &.active-submenu-l2 {
            display: block;
            padding: 0;
            border: 0;

            > a {
              display: none;
            }

            .dropdown-content-l2 {
              display: block;
              margin-top: 0;
              position: relative;
              border: 0;
              min-height: auto;

              li {
                display: block;
              }
            }
          }
        }
      }
    }
  }

  &.m3 {
    .filterzone {
      .category-zone {
        display: none;
      }
    }
  }

  .navbar-db {
    @include media-breakpoint-down(md) {
      display: none;
      @include flex-direction(column-reverse);
    }
  }

  .navbar-secondary {
    background: $blue;

    @include media-breakpoint-down(md) {
      background: $white
    }

    > .container {
      position: relative;
    }

    ul {
      &.menu-list-secondary {
        @include flex-direction(column);
        padding: 0 15px;

        @include media-breakpoint-up(lg) {
          margin-left: -12px;
          margin-right: -12px;
          width: calc(100% + 24px);
          padding: 0;
          @include flex-direction(row);
        }
      }
    }

    li {
      font-size: 12px;
      line-height: 20px;
      font-weight: $light;
      color: rgba($white, 0.75);
      letter-spacing: normal;
      padding: 10px 12px;

      @include media-breakpoint-down(md) {
        font-size: 14px;
        line-height: 20px;
        font-weight: $regular;
        color: $lightblue;
        padding: 0;
        border-bottom: 1px solid $grey;

        a {
          display: block;
          padding: 20px 10px;
          width: 100%;
        }
      }
    }
  }

  .navbar-primary {
    position: relative;
    background: $white;
    border-bottom: 1px solid $grey;
    @include transition(background 0.25s ease-in-out);
    @include transition(border-bottom-color 0.25s ease-in-out);

    @include media-breakpoint-down(md) {
      border: 0;
    }
  }

  .language-switch {
    position: absolute;
    top: 7px;
    right: 58px;
    display: block;
    color: $white;
    font-size: 12px;
    line-height: 20px;
    font-weight: $medium;

    &:hover {
      text-decoration: none;

      span {
        border-bottom: 1px solid $white;
      }
    }

    @include media-breakpoint-down(md) {
      display: none;
    }

    span {
      display: inline-block;
      vertical-align: middle;
      margin-left: 7px;
      border-bottom: 1px solid transparent;
    }

    svg {
      fill: $white;
      width: 14px;
      height: 14px;
      display: inline-block;
      vertical-align: middle;
    }
    &.language-switch-mobile {
        display: none;
        @include media-breakpoint-down(md) {
          display: block;
          top: 24px;
          right: 58px;
          svg {
              fill: $blue;
          }
        }
    }
  }

  .src-desk {
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50%;
    right: 15px;
    @include transform(translateY(-50%));

    @include media-breakpoint-down(md) {
      display: none;
    }

    img {
      display: block;

      &:hover {
        cursor: pointer;
      }

      &:active {
        outline: 0;
      }
    }
  }

  .src-mob {
    display: none;
    position: absolute;
    right: 15px;
    top: 50%;
    @include transform(translateY(-50%));

    @include media-breakpoint-down(md) {
      display: block;
    }
  }

  .js-nav-toggle {
    width: 16px;
    height: 14px;
    position: absolute;
    cursor: pointer;
    display: none;
    left: 15px;
    top: 50%;
    @include transform(translateY(-50%));

    @include media-breakpoint-down(md) {
      display: block;
    }

    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 16px;
      background: $blue;
      opacity: 1;
      left: 0;
      top: 6px;
      @include transform(rotate(0deg));
      @include transition(all 0.15s ease-in-out);

      &:first-of-type {
        top: 0px;
      }

      &:last-of-type {
        top: auto;
        bottom: 0;
      }
    }
  }

  .mobile-menu {
    display: none;
    position: relative;
    height: 74px;
    border-bottom: 1px solid $grey;
    background: $white;
    @include align-items(center);
    @include justify-content(center);
    @include transition(background 0.25s ease-in-out);

    @include media-breakpoint-down(md) {
      @include flexbox();
    }
  }

  .logo-desk {
    display: none;
    position: relative;

    @include media-breakpoint-up(lg) {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      @include transform(translate(-50%, -50%));
    }
  }

  .menu-list-primary {
    @include flex-direction(column);
    padding: 0 15px;

    @include media-breakpoint-up(lg) {
      margin-left: -18px;
      margin-right: -18px;
      width: calc(100% + 36px);
      padding: 0;
      @include justify-content(space-between);
      @include flex-direction(row);
    }

    @include media-breakpoint-up(xl) {
      margin-left: -28px;
      margin-right: -28px;
      width: calc(100% + 56px);
    }

    .menu-sct {
      @include media-breakpoint-down(md) {
        @include flex-direction(column);
      }

      > li {
        font-size: 15px;
        line-height: 20px;
        color: $blue;
        letter-spacing: 0.5px;
        font-weight: $medium;
        padding: 28px;

        @include media-breakpoint-down(lg) {
          padding: 28px 18px
        }

        @include media-breakpoint-down(md) {
          font-size: 14px;
          line-height: 20px;
          padding: 0;
          border-bottom: 1px solid $grey;
          position: relative;

          &:after {
            content: '';
            display: block;
            background: url('../app/images/right-grey-arrow-ico.svg') no-repeat;
            background-position: center center;
            width: 8px;
            height: 14px;
            background-size: 8px 14px;
            position: absolute;
            top: 50%;
            right: 10px;
            @include transform(translate(-50%, -50%));
          }
        }

        &.home-link {
          &:after {
            display: none;
          }
        }

        &:hover {
          @include media-breakpoint-up(lg) {
            cursor: pointer;

            > a:not(.cta) {
              &:after {
                display: block;
              }
            }

            .dropdown-content {
              display: block;
            }
          }
        }

        a:not(.cta) {
          position: relative;

          @include media-breakpoint-down(md) {
            display: block;
            width: 100%;
            padding: 20px 10px
          }

          &:after {
            content: '';
            display: none;
            width: 100%;
            height: 2px;
            background: $blue;
            left: 0;
            bottom: -27px;
            position: absolute;
          }

          &:hover,
          &:focus,
          &:active {
            text-decoration: none;
          }
        }
      }
    }
  }

  .dropdown-content,
  .dropdown-content-l2 {
    display: none;
    font-size: 16px;
    line-height: 24px;
    color: $black;
    font-weight: $light;
    position: absolute;
    left: 0;
    margin-top: 28px;
    background: $white;
    width: 100%;
    min-height: 415px;
    z-index: 1;
    cursor: default;
    border-top: 1px solid $grey;
    border-bottom: 1px solid $grey;

    .sbm-cnt,
    .prd-cnt {
      max-width: 1050px;
      margin: 0 auto;
      padding: 50px 15px;

      .inside-el {
        li {
          font-size: 15px;
          line-height: 20px;
          font-weight: $regular;
          padding: 0;
          border-top: 1px solid $grey;

          @include media-breakpoint-down(md) {
            font-size: 14px;
            line-height: 20px;
          }

          a {
            display: block;
            width: 100%;
            padding: 20px 0;
            position: relative;
            @include transition(all 0.15s ease);

            &:before {
              content: "";
              display: block;
              background: url(../app/images/right-long-grey-arrow-ico.svg) no-repeat;
              background-size: 15px 11px;
              width: 0;
              height: 11px;
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              @include transition(all 0.15s ease);
            }

            &:hover {
              @include media-breakpoint-up(lg) {
                padding-left: 25px;
                @include transition(all 0.15s ease);

                &:before {
                  width: 15px;
                  @include transition(all 0.15s ease);
                }
              }
            }
          }
        }
      }

      .inside {
        max-width: 400px;

        @include media-breakpoint-down(md) {
          max-width: 100%;
        }
      }

      .description {
        margin-bottom: 30px;

        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }

    .prd-cnt {
      max-width: $containermax;

      &.all {
        padding-left: 140px;
      }

      &.filterzone {
        @include media-breakpoint-down(md) {
          padding: 0;
        }

        .inside {
          max-width: 280px;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: -0.1px;
        }

        .data-item {
          display: none;

          &.active {
            display: block;

            @include media-breakpoint-down(md) {
              display: none;
            }
          }
        }

        .category-zone {
          ul {
            li {
              font-size: 12px;
              line-height: 24px;
              letter-spacing: 0.09px;
              padding: 4px 0;

              @include media-breakpoint-down(md) {
                font-size: 14px;
                line-height: 20px;
                padding: 0;
              }

              &:first-child {
                @include media-breakpoint-down(md) {
                  &:after {
                    content: '';
                    display: block;
                    background: url('../app/images/right-grey-arrow-ico.svg') no-repeat;
                    background-position: center center;
                    width: 8px;
                    height: 14px;
                    background-size: 8px 14px;
                    position: absolute;
                    top: 50%;
                    right: 10px;
                    @include transform(translate(-50%, -50%));
                  }
                }
              }

              a {
                @include media-breakpoint-up(lg) {
                  @include transition(all 0.15s ease);

                  &:after {
                    display: block;
                    left: 0;
                    top: 50%;
                    bottom: auto;
                    @include transform(translateY(-50%));
                    width: 0;
                    height: 2px;
                    @include transition(all 0.15s ease);
                  }
                }
              }

              &:hover,
              &.active {
                @include media-breakpoint-up(lg) {
                  a {
                    font-weight: $medium;
                    padding-left: 15px;
                    @include transition(all 0.15s ease);

                    &:after {
                      width: 10px;
                      @include transition(all 0.15s ease);
                    }
                  }
                }
              }
            }
          }
        }

        .description-zone {
          border-left: 1px solid $grey;

          @include media-breakpoint-down(md) {
            display: none;
          }

          .inside {
            max-width: 280px;
            margin: 0 auto;
          }
        }

        .gallery-zone {
          font-size: 15px;
          line-height: 20px;

          .image-col {
            margin-bottom: 40px;

            @include media-breakpoint-down(md) {
              margin-bottom: 0;
            }

            a {
              @include media-breakpoint-down(md) {
                border-bottom: 1px solid $grey;
              }

              &:hover {
                .product-image {
                  &:after {
                    width: 100%;
                    @include transition(all 0.25s ease);
                  }
                }
              }
            }

            .product-image {
              position: relative;
              margin-bottom: 10px;
              background: $whitesmoke;

              @include media-breakpoint-down(md) {
                display: none;
              }

              &:after {
                content: '';
                display: block;
                width: 0;
                height: 3px;
                background: $blue;
                position: absolute;
                left: 0;
                bottom: 0;
                @include transition(all 0.25s ease);
              }
            }
          }
        }
      }
    }

    .big-submenu {
      border-bottom: 1px solid $grey;

      @include media-breakpoint-down(md) {
        border: 0;
      }

      .deep-menu {
        @include media-breakpoint-up(lg) {
          margin-left: -18px;
          margin-right: -18px;
          width: calc(100% + 36px);
        }

        @include media-breakpoint-down(md) {
          @include flex-direction(column);
        }

        li {
          font-size: 15px;
          line-height: 20px;
          font-weight: $regular;
          letter-spacing: normal;
          color: $blue;
          padding: 21px 18px 22px;

          @include media-breakpoint-down(md) {
            font-size: 14px;
            line-height: 20px;
            padding: 0;
            border-bottom: 1px solid $grey;
            position: relative;
          }

          &:not(:first-child) {
            &:after {
              @include media-breakpoint-down(md) {
                content: '';
                display: block;
                background: url('../app/images/right-grey-arrow-ico.svg') no-repeat;
                background-position: center center;
                width: 8px;
                height: 14px;
                background-size: 8px 14px;
                position: absolute;
                top: 50%;
                right: 10px;
                @include transform(translate(-50%, -50%));
              }
            }
          }

          a {
            &:after {
              bottom: -21px;
            }
          }

          &:hover,
          &.active {
            @include media-breakpoint-up(lg) {
              &:hover {
                cursor: pointer;
              }

              a {
                &:after {
                  display: block !important;
                }
              }

              .dropdown-content-l2 {
                display: block;
              }
            }
          }
        }
      }
    }
  }

  .search-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 117px;
    background: $blue;
    display: none;

    @include media-breakpoint-down(md) {
      height: 74px;
    }

    &.active {
      display: block;
    }

    .search-input {
      width: 100%;
      height: 100%;
      margin: 0;
      @include flexbox();
      @include justify-content(center);
      @include align-items(center);
    }

    .form-group {
      width: 100%;
      max-width: 670px;
      margin: 0;

      @include media-breakpoint-down(md) {
        max-width: calc(100% - 80px);
      }

      input {
        border: 0;

        @include media-breakpoint-down(md) {
          padding: 10px 25px 10px 50px;
        }
      }
    }

    .crossico {
      right: 30px;
      top: 15px;
      @include transform(none);

      @include media-breakpoint-down(md) {
        right: 15px;
      }

      &:hover {
        cursor: pointer;
      }

      &:before,
      &:after {
        background-color: $white;
      }
    }
  }

  .dropdown-content-l2 {
    margin-top: 22px;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      a {
        color: inherit;

        &:hover,
        &:focus,
        &:active {
          color: inherit;
        }
      }
    }
  }
}
