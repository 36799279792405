// Main search component

.component-search-main {
  padding: 80px 0;

  @include media-breakpoint-down(md) {
    padding: 60px 0;
  }

  .inside {
    max-width: 670px;
    margin: 0 auto;
    text-align: center;

    h3 {
      margin-bottom: 30px;

      @include media-breakpoint-down(sm) {
        margin-bottom: 15px;
      }
    }
  }
}