// Product Hero
.component-productdetail {
  /*height: calc(100vh - 117px);*/

  @include media-breakpoint-down(md) {
    height: 100%;
  }

  > .container {
    @include media-breakpoint-up(lg) {
      height: 100%;
      @include flexbox();
      @include flex-direction(column);
      @include justify-content(flex-end);
    }
  }

  .productdetailrow {
    height: 100%;
    padding: 40px 0 15px;
    @include align-items(center);

    @include media-breakpoint-down(md) {
      padding: 40px 0;
    }
  }

  .inside {
    max-width: 440px;
    margin: 0 auto;

    @include media-breakpoint-down(md) {
      max-width: 533px;
    }
  }

  .info-sct {
    @include media-breakpoint-down(md) {
      margin-bottom: 30px;
    }

    h1 {
      font-size: 96px;
      line-height: 112px;
      letter-spacing: -2.82px;
      font-weight: $regular;

      @include media-breakpoint-down(md) {
        font-size: 60px;
        line-height: 76px;
        letter-spacing: -1.76px;
      }
    }

    .small-intro {
      margin-bottom: 40px;

      @include media-breakpoint-down(md) {
        margin-bottom: 15px;
      }
    }

    .description {
      max-width: 320px;

      @include media-breakpoint-down(md) {
        max-width: 100%;
      }
    }
  }

  .img-sct {
    @include media-breakpoint-down(md) {
      max-width: 533px;
      margin: 0 auto;
      text-align: center;
    }
  }

  .breadcrumbs {
    margin-bottom: 25px;

    @include media-breakpoint-down(md) {
      margin-bottom: 10px;
      width: 100%;
      overflow: auto;
      @include flex-wrap(nowrap);
    }

    li {
      @include media-breakpoint-down(md) {
        white-space: nowrap;
      }
    }

    *,
    li:after {
      color: $blue;
    }
  }
}