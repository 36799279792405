// Download list component
.component-downloadlisting {
  margin-bottom: 200px;

  @include media-breakpoint-down(md) {
    margin-bottom: 80px;
  }

  .minititle {
    @include media-breakpoint-down(md) {
      border: 0;
      margin-bottom: 0;
    }
  }

  .downloadlist {
    p {
      @include media-breakpoint-down(md) {
        font-size: 14px;
        line-height: 24px;
      }
    }

    a {
      color: inherit;
    }

    .listingrow {
      padding: 23px 0;
      position: relative;

      &:after {
        content: '';
        display: block;
        background: $grey;
        width: calc(100% - 30px);
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 15px;
      }

      &:first-of-type {
        &:before {
          content: '';
          display: block;
          background: $grey;
          width: calc(100% - 30px);
          height: 1px;
          position: absolute;
          top: 0;
          bottom: auto;
          left: 15px;
        }
      }

      &:hover {
        @include media-breakpoint-up(lg) {
          cursor: pointer;

          * {
            color: $royalblue;
            text-decoration: underline;
          }

          svg {
            .svg-color {
              fill: $royalblue;
            }

            .svg-opacity {
              opacity: 1;
            }
          }

          .filetype {
            color: rgba($black, 0.5);
            text-decoration: none;

            p {
              color: inherit;
              text-decoration: inherit;
            }
          }
        }
      }

      .filetype {
        color: rgba($black, 0.5);

        @include media-breakpoint-down(md) {
          text-align: right;
        }
      }

      .download-col {
        a {
          text-align: right;
          display: block;
          margin-right: 20px;

          @include media-breakpoint-down(md) {
            margin-right: 0;
          }
        }
      }
    }
  }
}