// 50/50 Feature
.component-feature2col {
  > .container {
    @include media-breakpoint-down(md) {
      max-width: 563px;
    }
  }

  .feature2colrow {
    margin-bottom: 25px;
    @include justify-content(center);
    @include align-items(center);

    @include media-breakpoint-down(md) {
      margin-bottom: 65px;
    }

    &:nth-child(even) {
      @include flex-direction(row-reverse);
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .inside {
    max-width: 440px;
    margin: 0 auto;

    @include media-breakpoint-down(md) {
      max-width: 100%;
    }

    .description {
      max-width: 400px;

      @include media-breakpoint-down(md) {
        max-width: 100%;
      }
    }

    .cta {
      margin-top: 40px;

      @include media-breakpoint-down(md) {
        width: 100%;
        text-align: center;
        padding-left: 25px;
      }
    }
  }

  .ico-sct {
    margin-bottom: 25px;
  }

  .img-sct {
    @include media-breakpoint-down(md) {
      margin-bottom: 30px;
    }

    img {
      width: 100%;
    }
  }
}