// Sitemap
.component-sitemap {
  margin-top: 50px;

  .sitemap-row {
    margin-bottom: 100px;

    @include media-breakpoint-down(md) {
      margin-bottom: 0;
    }
  }

  .sitemap-col {
    @include media-breakpoint-down(md) {
      margin-bottom: 60px;
    }
  }

  .minititle {
    a {
      color: $blue;
      position: relative;
      @include transition(all 0.25s ease);

      &:after {
        content: "";
        display: block;
        background: url(../app/images/right-long-grey-arrow-ico.svg) no-repeat;
        background-size: 15px 11px;
        width: 0;
        height: 11px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        @include transition(all 0.25s ease);
      }

      &:hover {
        @include media-breakpoint-up(lg) {
          text-decoration: none;
          padding-left: 25px;
          @include transition(all 0.25s ease);

          &:after {
            width: 15px;
            @include transition(all 0.25s ease);
          }
        }
      }
    }
  }

  .sitemap-item {
    padding: 20px 0;
    position: relative;

    @include media-breakpoint-down(md) {
      padding: 15px 0;
    }

    &:before {
      content: '';
      display: block;
      width: 208px;
      height: 1px;
      background: $grey;
      position: absolute;
      top: 0;
      left: 0;

      @include media-breakpoint-down(md) {
        top: -1px;
        width: 100%;
      }
    }

    &:first-of-type {
      &:before {
        @include media-breakpoint-down(md) {
          top: auto;
          bottom: 0;
        }
      }
    }
  }

  .sitemap-product {
    padding: 10px 0;
  }

  .sitemap-product,
  .sitemap-item {
    font-size: 15px;
    line-height: 20px;
    color: $blue;
    font-weight: $regular;
    display: block;
    position: relative;
    @include transition(all 0.25s ease);

    &:after {
      content: "";
      display: block;
      background: url(../app/images/right-long-grey-arrow-ico.svg) no-repeat;
      background-size: 15px 11px;
      width: 0;
      height: 11px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      @include transition(all 0.25s ease);
    }

    &:hover {
      @include media-breakpoint-up(lg) {
        text-decoration: none;
        padding-left: 25px;
        @include transition(all 0.25s ease);

        &:after {
          width: 15px;
          @include transition(all 0.25s ease);
        }
      }
    }
  }
}