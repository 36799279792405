@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');

// Rich Text Editor
h1 {
  font-size: 48px;
  line-height: 64px;
  letter-spacing: -0.2px;

  @include media-breakpoint-down(md) {
    font-size: 40px;
    line-height: 52px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 28px;
    line-height: 40px;
  }
}

h2 {
  font-size: 36px;
  line-height: 52px;
  letter-spacing: -0.2px;

  @include media-breakpoint-down(lg) {
    font-size: 28px;
    line-height: 40px;
  }
}

h3 {
  font-size: 28px;
  line-height: 40px;
  letter-spacing: -0.2px;

  @include media-breakpoint-down(lg) {
    font-size: 20px;
    line-height: 32px;
  }

  @include media-breakpoint-down(md) {
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.09px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 20px;
    line-height: 32px;
  }
}

h4 {
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.2px;

  @include media-breakpoint-down(lg) {
    font-size: 16px;
    line-height: 28px;
  }
}

h5 {
  font-size: 16px;
  line-height: 26px;

  @include media-breakpoint-down(md) {
    font-size: 12px;
    line-height: 24px;
  }
}

h6 {
  font-size: 14px;
  line-height: 24px;
}

h1, h2, h3, h4 {
  color: $blue;
  font-weight: $light;
  margin-bottom: 15px;
}

h5, h6 {
  color: $blue;
  font-weight: $medium;
  letter-spacing: -0.1px;
  margin-bottom: 15px;
}

.largefigure {
  font-size: 72px;
  line-height: 96px;
  font-weight: $thin;
  letter-spacing: -0.6px;
  color: $blue;
}

.tags {
  font-size: 10px;
  line-height: 20px;
  font-weight: $medium;
  letter-spacing: 1.2px;
  color: $black;
  text-transform: uppercase;

  &.newstags {
    background: rgba($lighterblue, 0.12);
    border-radius: 14px;
    padding: 2px 12px;
    margin-bottom: 25px;
    display: inline-block;
    @include transition(background 0.25s ease);
  }
}

.dates,
.filtercategories {
  font-size: 12px;
  line-height: 20px;
  font-weight: $light;
  letter-spacing: 0.6px;
  color: $black;
}

.buttons {
  font-size: 12px;
  line-height: 20px;
  font-weight: $regular;
  letter-spacing: 0.6px;
  color: $blue;
}

.headerlinks {
  font-size: 15px;
  line-height: 20px;
  font-weight: $medium;
  letter-spacing: 0.5px;
  color: $blue;
}

.breadcrumbs {
  margin: 0;
  padding: 0;
  list-style-type: none;
  @include flexbox();

  li {
    font-size: 10px;
    line-height: 20px;
    font-weight: $medium;
    letter-spacing: 2px;
    color: $blue;
    text-transform: uppercase;
    padding-right: 5px;
    position: relative;
    opacity: 0.7;

    &:last-child {
      opacity: 1;

      &:after {
        display: none;
      }
    }

    &:after {
      content: '/';
      color: $white;
      display: inline-block;
      padding-left: 5px;
    }
  }
}

// Display fonts
.display1 {
  font-size: 56px;
  line-height: 84px;
  letter-spacing: 11px;

  @include media-breakpoint-down(md) {
    font-size: 48px;
    line-height: 72px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 28px;
    line-height: 48px;
    letter-spacing: 6.56px;
  }
}

.display2 {
  font-size: 48px;
  line-height: 70px;
  letter-spacing: 11px;

  @include media-breakpoint-down(sm) {
    font-size: 28px;
    line-height: 48px;
    letter-spacing: 6.56px;
  }
}

.display3 {
  font-size: 28px;
  line-height: 48px;
  letter-spacing: 7px;
  font-weight: $light;
}

.display4 {
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 4px;
  font-weight: $regular;

  @include media-breakpoint-down(sm) {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 3px;
  }
}

.display1,
.display2 {
  font-weight: $light;
}

.display1,
.display2,
.display3, 
.display4 {
  color: $blue;
}

// Extra elements
.cta {
  font-size: 12px;
  line-height: 20px;
  font-weight: $regular;
  color: $white;
  letter-spacing: 0.2px;
  background: $purple;
  padding: 11px 25px 12px 45px;
  border: 1px solid $purple;
  border-radius: 30px;
  display: table;
  position: relative;

  &:before {
    content: '';
    display: block;
    background: url('../app/images/right-arrow-ico.svg') no-repeat;
    background-size: 7px 11px;
    width: 7px;
    height: 11px;
    position: absolute;
    top: 50%;
    left: 20px;
    @include transform(translateY(-50%));
  }

  &:hover,
  &:focus,
  &:active {
    color: $white;
    text-decoration: none;

    &:before {
      background: url('../app/images/right-long-arrow-ico.svg') no-repeat;
      background-size: 15px 11px;
      width: 15px;
      height: 11px;
    }
  }

  &.external-link-video {
    &:before {
      background: url('../app/images/play-icon-white.svg') no-repeat;
      background-size: 15px 15px;
      width: 15px;
      height: 15px;
    }
  }

  &.external-link {
    &:before {
      background: url('../app/images/external-link-cta-ico-white.svg') no-repeat;
      background-size: 17px 17px;
      width: 17px;
      height: 17px;
    }
  }

  &:active {
    opacity: 0.9;
  }
  // to update after new icons
  &.keyline {
    background: $white;
    border-color: $blue;
    color: $blue;

    &:before {
      background: url('../app/images/right-grey-arrow-ico.svg') no-repeat;
      background-size: 7px 11px;
      width: 7px;
      height: 11px;
    }

    &:hover,
    &:focus,
    &:active {
      color: $blue;
      text-decoration: none;

      &:before {
        background: url('../app/images/right-long-grey-arrow-ico.svg') no-repeat;
        background-size: 15px 11px;
        width: 15px;
        height: 11px;
      }
    }

    &:active {
      opacity: 1;
    }

    &.disabled {
      border-color: $ctakeydisabled;
      color: rgba($blue, 0.5);
      background: $white;

      &:before {
        opacity: 0.5;
      }
    }

    &.external-link-video {
      &:before {
        background: url('../app/images/play-icon-blue.svg') no-repeat;
        background-size: 15px 15px;
        width: 15px;
        height: 15px;
      }
    }

    &.external-link {
      &:before {
        background: url('../app/images/external-link-cta-ico.svg') no-repeat;
        background-size: 17px 17px;
        width: 17px;
        height: 17px;
      }
    }
  }

  &.nobg {
    background: transparent;
    border-color: rgba($white, 0.4);
    @include transition(border .15s ease-in-out);

    &:hover,
    &:focus,
    &:active {
      border-color: $white;
      @include transition(border .15s ease-in-out);
    }

    &.external-link-video {
      &:before {
        background: url('../app/images/play-icon-white.svg') no-repeat;
        background-size: 15px 15px;
        width: 15px;
        height: 15px;
      }
    }

    &.external-link {
      &:before {
        background: url('../app/images/external-link-cta-ico-white.svg') no-repeat;
        background-size: 17px 17px;
        width: 17px;
        height: 17px;
      }
    }
  }
  // to update after new icons
  &.disabled {
    background: $ctadisabled;
    color: rgba($blue, 0.5);
    border-color: $ctadisabled;

    &:before {
      background: url('../app/images/right-grey-arrow-ico.svg') no-repeat;
      background-size: 7px 11px;
      width: 7px;
      height: 11px;
    }

    &:hover,
    &:focus,
    &:active {
      color: rgba($blue, 0.5);
      text-decoration: none;

      &:before {
        background: url('../app/images/right-long-grey-arrow-ico.svg') no-repeat;
        background-size: 15px 11px;
        width: 15px;
        height: 11px;
      }
    }

    &:active {
      opacity: 1;
    }
  }
}

p {
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }

  &.smallc {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
  }

  a {
    color: $lightblue;
  }
}

.smallc {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;

  p {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
  }
}

b, strong {
  font-weight: $bold;
}

a {
  &:active {
    outline: 0;
  }
}

.social-ico {
  width: 44px;
  height: 44px;
  background: $white;
  border: 1px solid rgba($blue, 0.4);
  border-radius: 50%;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  @include transition(border 0.15s ease-in-out);

  &:hover {
    cursor: pointer;
    border-color: $blue;
    @include transition(border 0.15s ease-in-out);
  }
}

// Dark scheme
.bluebg {
  background: $blue;

  * {
    color: $white;
  }

  a {
    color: $white;

    &:hover,
    &:focus,
    &:active {
      color: $white;
    }
  }
}

// FAQ ico
.crossico {
  position: absolute;
  right: 10px;
  top: 50%;
  width: 20px;
  height: 20px;
  @include transform(translateY(-50%));

  &:before,
  &:after {
    position: absolute;
    left: 10px;
    content: ' ';
    height: 20px;
    width: 1px;
    background-color: $blue;
    @include transition(transform 0.15s ease);
  }

  &:after {
    @include transform(rotate(90deg));
  }
}

.active {
  .crossico {
    &:after {
      @include transform(rotate(-45deg));
      @include transition(transform 0.15s ease);
    }

    &:before {
      @include transform(rotate(-135deg));
      @include transition(transform 0.15s ease);
    }
  }
}

// 2 column title zone
.minititle {
  font-size: 16px;
  line-height: 26px;
  color: $blue;
  font-weight: $medium;
  letter-spacing: -0.1px;
  border-top: 1px solid $grey;
  padding-top: 15px;

  @include media-breakpoint-down(md) {
    border-top: 0;
    padding-top: 0;
    padding-bottom: 15px;
    margin-bottom: 25px;
    border-bottom: 1px solid $grey;
  }

  @include media-breakpoint-down(sm) {
    font-size: 14px;
    line-height: 24px;
  }
}

.minititle-no-border {
  font-size: 16px;
  line-height: 26px;
  color: $blue;
  font-weight: $medium;
  letter-spacing: -0.1px;
  margin-bottom: 25px;

  @include media-breakpoint-down(sm) {
    font-size: 14px;
    line-height: 24px;
  }
}

// custom progressbar
.progress-bar {
  display: block;
  width: 100%;
  height: 3px;
  overflow: hidden;
  background-color: $lighterblueup;
  background-image: linear-gradient(to right, $blue, $blue);
  background-repeat: no-repeat;
  background-size: 0 100%;
  @include transition(background-size .4s ease-in-out);
}

// custom checkbox
.custom-checkbox {
  font-size: 12px;
  line-height: 20px;
  min-height: auto;
  margin-bottom: 10px;
  padding: 0;

  &.input-group-prepend {
    display: table;
    position: relative;

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
      z-index: 2;
      margin: 0;
      opacity: 0;

      &:hover {
        cursor: pointer;
      }
    }

    input:hover + label {
      &:after {
        background: url(../app/images/checkbox2-ico.svg) no-repeat;
        background-size: 15px 15px;
        width: 15px;
        height: 15px;
        background-color: $white;
      }
    }
  }

  &.active {
    label {
      &:after {
        opacity: 0;
      }
    }
  }

  .custom-control-input {
    &:checked ~ .custom-control-label::before,
    &:focus ~ .custom-control-label::before {
      background-color: $white;
      box-shadow: none;
    }
  }

  label {
    font-size: 12px;
    line-height: 20px;
    font-weight: $light;
    color: $black;
    padding-left: 25px;
    position: relative;

    &:after,
    &:before {
      top: 2px;
      left: 0;
      box-shadow: none;
      border-radius: 0;
    }

    &:after {
      background: url('../app/images/checkbox1-ico.svg') no-repeat;
      background-size: 15px 15px;
      width: 15px;
      height: 15px;
      background-color: $white;
    }

    &:before {
      background: url('../app/images/checkbox3-ico.svg') no-repeat;
      background-size: 15px 15px;
      width: 15px;
      height: 15px;
      background-color: $white;
    }

    &:hover {
      cursor: pointer;

      &:after {
        background: url('../app/images/checkbox2-ico.svg') no-repeat;
        background-size: 15px 15px;
        width: 15px;
        height: 15px;
        background-color: $white;
      }
    }
  }
}

// custom form input
.form-group {
  input {
    font-size: 14px;
    line-height: 20px;
    font-weight: $light;
    color: rgba($black, 0.7);
    border: 1px solid $grey;
    border-radius: 0;
    padding: 17px 25px;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: rgba($black, 0.7);
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: rgba($black, 0.7);
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: rgba($black, 0.7);
    }

    &:focus {
      font-weight: $regular;
      border-color: $blue;
      box-shadow: none;
      outline: 0;
      color: rgba($blue, 0.7);
    }
  }
}

// custom select container
.select2-container {
  min-width: 100%;
  max-width: 100%;

  &.select2-container--open {
    .select2-selection {
      outline: 0;

      &:focus {
        outline: 0;
      }

      .select2-selection__arrow {
        @include transform(translateY(-50%) rotate(-180deg));
        @include transition(transform 0.15s ease);
      }
    }
  }

  .select2-results__options {
    height: 200px !important;
  }

  .select2-selection {
    border: 1px solid $grey;
    border-radius: 0;
    height: auto;
    padding: 17px 25px;
    outline: 0;

    .select2-selection__rendered {
      font-size: 14px;
      line-height: 20px;
      color: $black;
      padding: 0;
    }

    .select2-selection__arrow {
      width: 10px;
      height: 6px;
      top: 50%;
      right: 25px;
      @include transform(translateY(-50%) rotate(0));
      @include transition(transform 0.15s ease);

      b {
        background: url('../app/images/down-arrow-blue-ico.svg') no-repeat;
        background-size: 10px 6px;
        width: 10px;
        height: 6px;
        border: 0;
        left: auto;
        top: auto;
        margin: 0;
      }
    }
  }
}

// custom select dropdown
.select2-container--open {
  .select2-dropdown {
    border: 1px solid $grey;
    border-top-color: transparent;
    border-radius: 0;
    z-index: 2;

    .select2-results__option {
      font-size: 14px;
      line-height: 20px;
      font-weight: $light;
      color: $black;
      padding: 10px 25px;
      position: relative;

      &.select2-results__option--selected {
        &:after {
          content: '';
          display: block;
          background: url('../app/images/selected-item-green-ico.svg') no-repeat;
          background-color: transparent;
          background-size: 13px 8px;
          width: 13px;
          height: 8px;
          position: absolute;
          top: 50%;
          right: 25px;
          @include transform(translateY(-50%));
        }
      }

      &.select2-results__option--highlighted {
        background-color: $whitesmoke;
        color: $lightblue;
        text-decoration: underline;
      }
    }
  }
}

.select2-container--default {
  .select2-results__option--selected {
    background: transparent;
  }
}

// custom scrollbar
.scrollbar-inner {
  > .scroll-element {
    &.scroll-y {
      width: 1px;
      right: 15px;
      top: 15px;
      height: calc(100% - 30px);
    }

    .scroll-bar {
      background: $blue;
    }

    .scroll-element_track {
      background-color: rgba($blue, 0.5);
      border-radius: 0;
    }
  }
}

// search input
.search-input {
  @include media-breakpoint-down(md) {
    margin-bottom: 30px;
  }

  .form-group {
    position: relative;

    &:before {
      content: '';
      display: block;
      background: url(/app/images/search-ico-grey.svg) no-repeat;
      background-size: 15px 15px;
      width: 15px;
      height: 15px;
      position: absolute;
      top: 50%;
      left: 25px;
      @include transform(translateY(-50%));
    }

    input {
      padding-left: 50px;
    }
  }
}

.bg-bullet-list {
  list-style: none;
  counter-reset: steps;
  margin: 0;
  padding: 0;

  li {
    font-size: 28px;
    line-height: 40px;
    font-weight: $light;
    counter-increment: steps;
    margin-bottom: 95px;
    @include flexbox();
    @include align-items(center);

    @include media-breakpoint-down(md) {
      font-size: 22px;
      line-height: 34px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 35px;
      @include flex-direction(column);
      @include align-items(flex-start);
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      font-size: 96px;
      line-height: 1.2em;
      font-weight: $thin;
      color: $lighterblue;
      margin-right: 75px;
      content: counter(steps);
      background: transparent;

      @include media-breakpoint-down(sm) {
        font-size: 72px;
      }
    }
  }
}