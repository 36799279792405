// Innovation Component
.component-innovationfeature {
  margin-bottom: 30px;

  .innovation-bg {
    position: relative;

    img {
      width: 100%;
    }

    .inside {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      @include flexbox();
      @include align-items(center);

      @include media-breakpoint-down(md) {
        @include align-items(flex-end);
      }
    }

    .infotext {
      width: 100%;
      padding: 0 115px;

      @include media-breakpoint-down(lg) {
        padding: 0 80px;
      }

      @include media-breakpoint-down(md) {
        padding: 0 25px 30px;
        max-width: 583px;
        margin: 0 auto 70px;
      }

      @include media-breakpoint-down(sm) {
        margin-bottom: 0;
      }

      .link-box {
        max-width: 360px;

        @include media-breakpoint-down(md) {
          max-width: 100%;
        }

        a {
          color: inherit;
        }
      }

      h1 {
        @include media-breakpoint-down(lg) {
          font-size: 28px;
          line-height: 40px;
        }

        @include media-breakpoint-down(md) {
          font-size: 48px;
          line-height: 64px;
        }

        @include media-breakpoint-down(sm) {
          font-size: 28px;
          line-height: 40px;
        }
      }

      .slicelink {
        margin: 0;

        @include media-breakpoint-down(lg) {
          font-size: 16px;
          line-height: 28px;
        }

        @include media-breakpoint-down(md) {
          font-size: 20px;
          line-height: 32px;
        }

        @include media-breakpoint-down(sm) {
          font-size: 16px;
          line-height: 28px;
        }

        a {
          border-bottom: 1px solid $blue;
          margin-bottom: 0;
          padding: 15px 0;
          display: block;
          position: relative;

          @include media-breakpoint-down(lg) {
            padding: 12px 0;
          }

          &:hover {
            text-decoration: none;

            &:after {
              background: url('../app/images/right-long-grey-arrow-ico.svg') no-repeat;
              background-size: 15px 11px;
              width: 15px;
              height: 11px;
            }
          }

          &:after {
            content: '';
            display: block;
            background: url('../app/images/right-grey-arrow-ico.svg') no-repeat;
            background-size: 8px 14px;
            width: 8px;
            height: 14px;
            position: absolute;
            top: 50%;
            right: 10px;
            @include transform(translateY(-50%));
          }
        }
      }
    }
  }
}