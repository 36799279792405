// 4 column link card
.component-4colcardlinks {
  background: $whitesmoke;
  padding: 80px 0 60px;

  @include media-breakpoint-down(md) {
    padding: 25px 0 5px;
  }

  .card-col {
    margin-bottom: 20px;

    @include media-breakpoint-up(lg) {
      @include flex(1);
    }

    @include media-breakpoint-only(md) {
      margin-bottom: 30px;
    }

    .inside {
      height: 100%;
      padding: 40px;
      background: $white;
      @include flexbox();
      @include flex-direction(column);
      @include justify-content(flex-end);

      @include media-breakpoint-down(lg) {
        padding: 25px;
      }

      .img-sct,
      .description {
        margin-bottom: 40px;
      }

      .description {
        @include flex-grow(1);
      }

      .cta {
        @include media-breakpoint-down(md) {
          width: 100%;
          text-align: center;
          padding-left: 25px;
        }
      }
    }
  }
}