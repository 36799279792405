// Latest press component
.component-latestpress {
  margin-bottom: 120px;

  @include media-breakpoint-down(md) {
    margin-bottom: 65px;
  }

  .minititle {
    @include media-breakpoint-up(lg) {
      margin-bottom: 50px;
    }
  }

  .hidden-dkt {
    a {
      @include media-breakpoint-down(md) {
        width: 100%;
        text-align: center;
        padding-left: 25px;
      }
    }
  }

  .col {
    @include flex(1);

    @include media-breakpoint-down(md) {
      @include flex(none);
    }

    &:first-of-type {
      @include media-breakpoint-down(md) {
        margin-bottom: 30px;
      }

      .inside {
        @include media-breakpoint-down(md) {
          border-top: 0;
          padding-top: 0;

          &:before {
            display: none;
          }
        }
      }
    }

    &:not(:first-of-type) {
      @include media-breakpoint-down(sm) {
        display: none;
      }

      .inside {
        @include media-breakpoint-down(md) {
          margin-bottom: 25px;
        }

        @include media-breakpoint-down(sm) {
          margin-bottom: 0;
        }
      }
    }

    &:last-of-type {
      @include media-breakpoint-down(md) {
        margin-bottom: 25px;

        &:after {
          content: '';
          display: block;
          background: $grey;
          height: 1px;
          width: calc(100% - 30px);
          position: absolute;
          bottom: 0;
          left: 15px;
        }
      }
    }

    .extrainfo {
      .extracol {
        @extend .col-12;

        &:not(:first-of-type) {
          display: none;
        }
      }
    }

    .inside {
      border-top: 1px solid $grey;
      padding-top: 25px;
      position: relative;
      height: 100%;
      @include flexbox();
      @include flex-direction(column);

      @include media-breakpoint-only(md) {
        border: 0;
        margin-left: -15px;
        margin-right: -15px;
        @include flexbox();
        @include flex-wrap(wrap);
        @include flex-direction(row);

        &:before {
          content: '';
          display: block;
          background: $grey;
          height: 1px;
          width: calc(100% - 30px);
          position: absolute;
          top: 0;
          left: 15px;
        }
      }

      @include media-breakpoint-down(sm) {
        padding-top: 0;
      }

      &:hover {
        text-decoration: none;
        color: inherit;

        @include media-breakpoint-up(lg) {
          &:after {
            width: 100%;
            @include transition(all 0.25s ease);
          }

          h4 {
            text-decoration: underline;
          }

          .newstags {
            background: $blue;
            color: $white;
            @include transition(background 0.25s ease);
          }

          .dates {
            text-decoration: none;
          }
        }
      }

      &:after {
        content: '';
        display: block;
        width: 0;
        height: 3px;
        background: $blue;
        position: absolute;
        left: 0;
        bottom: 0;
        @include transition(all 0.25s ease);
      }

      .info-press,
      h4 {
        @include flex-grow(1);
      }

      .info-press {
        @include flexbox();
        @include flex-direction(column);
        @include justify-content(stretch);

        @include media-breakpoint-only(md) {
          padding: 0 15px;
          max-width: 50%;
          @include flex(0 0 50%);
        }
      }

      .inside-img {
        @include media-breakpoint-only(md) {
          padding: 0 15px;
          max-width: 50%;
          @include flex(0 0 50%);
        }
      }
    }
  }

  .dates,
  .filtercategories {
    color: rgba($black, 0.6);
    margin-bottom: 15px;
  }

  .inside-img {
    img {
      width: 100%;
    }
  }
  // event scenario
  .fullevent {
    margin-bottom: 25px;
    padding-bottom: 25px;
    position: relative;

    &:after,
    &:before {
      content: '';
      display: block;
      background: $grey;
      width: calc(100% - 30px);
      height: 1px;
      position: absolute;
      left: 15px;
      bottom: 0;
    }

    .inside {
      height: 100%;
      max-width: 540px;
      @include flexbox();
      @include flex-direction(column);

      @include media-breakpoint-down(md) {
        max-width: 100%;
      }

      h4 {
        @include flex(1);
      }

      .dates {
        color: rgba($black, 0.6);
      }
    }
  }

  a.eventeaser,
  .js-fakelink {
    &:hover {
      text-decoration: none;
      color: inherit;

      @include media-breakpoint-up(lg) {
        .img-cnt {
          &:after {
            width: 100%;
            @include transition(all 0.25s ease);
          }
        }

        h4 {
          text-decoration: underline;
        }

        .newstags {
          background: $blue;
          color: $white;
          @include transition(background 0.25s ease);
        }

        .dates {
          text-decoration: none;
        }
      }
    }
  }

  .eventeaser {
    position: relative;
    overflow: hidden;
    display: block;

    &.js-fakelink {
      &:hover {
        cursor: pointer;
      }

      .infoarea {
        * {
          display: none;
        }

        .name,
        .smallc {
          display: block;
        }
      }
    }

    &:first-of-type {
      .fullevent {
        padding-top: 25px;

        @include media-breakpoint-down(md) {
          padding-top: 0;
          padding-bottom: 25px;
          margin-bottom: 25px;
        }

        @include media-breakpoint-down(sm) {
          padding-bottom: 0;
        }

        &:before {
          bottom: auto;
          top: 0;

          @include media-breakpoint-down(md) {
            display: none;
          }
        }

        &:after {
          @include media-breakpoint-down(sm) {
            display: none;
          }
        }

        .extrainfo {
          @include media-breakpoint-down(sm) {
            margin-bottom: 25px;

            .extracol {
              @include flexbox();
              @include flex-wrap(wrap);
              @include flex-direction(row);

              .tags {
                width: 100px;
              }

              .dates {
                line-height: 20px;
              }
            }
          }
        }
      }
    }

    &:not(:first-of-type) {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    .dates {
      line-height: 1;
      margin-bottom: 0;
    }
  }

  .extrainfo {
    @include media-breakpoint-down(sm) {
      margin-bottom: 25px;

      .extracol {
        @include flexbox();
        @include flex-wrap(wrap);
        @include flex-direction(row);

        .tags {
          width: 100px;
        }

        .dates {
          line-height: 20px;
        }
      }
    }
  }

  .img-cnt {
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 0;
      height: 3px;
      background: $blue;
      position: absolute;
      left: 0;
      bottom: 0;
      @include transition(all 0.25s ease);
    }

    img {
      width: 100%;
    }
  }
}